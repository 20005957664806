import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSiteContext } from '../../../context/useSiteContext';
import { useUrlParams } from '../../../helpers/urlParams';
import * as siteSelectors from '../../../modules/sites/selectors';
import useApartmentFormValidationSchema from '../../ApartmentAutocomplete/useApartmentFormValidationSchema';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();
  const isFloorRequired = useSiteContext().isSiteFeatureEnabled('MANDATORY_FLOOR');

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(useApartmentFormValidationSchema(isFloorRequired, companyId, siteId)),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    generalInfo: { companyId, siteId },
    methods,
  };
}
