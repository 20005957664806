import { styled } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { getIsDisableAddButton } from '../../containers/DeviceDetail/CallSettings/Intercom/helpers/helpers';
import { IButtonRenderDataV2 } from '../../containers/DeviceDetail/CallSettings/Intercom/IntercomV2Data';
import { useModal } from '../../hooks/useModal';
import { palette } from '../../theme';
import { createColumnsDefinition } from './columnsBuilder';
import { FormTableToolbar } from './components/FormTableToolbar';
import {
  UnsaveChangesModal as UnsaveChangesModalComponent,
  UnsaveChangesModalContext,
} from './components/UnsaveChangesModal';
import { IAddNewRowOnInit, IFormActions, IFormTableData, IPagination, IUpdateResult } from './data';
import { areThereSomeUnsavedChanges, getTargetPage } from './formTableHelpers';
import { IFormState, IIndexedRow, InnerTRow, Primitive, useArrayForm } from './hooks/useCustomForm';

// temporarily solution to table have own scroll
const headerUnderTableHeight = 310;

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .dataGrid-form-row-saved': {
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    backgroundColor: theme.palette.success.light,
  },
  '& .dataGrid-form-row-updated': {
    '&:hover': {
      backgroundColor: palette.primary.create,
    },
    backgroundColor: palette.primary.create,
  },

  '& .MuiDataGrid-row': {
    '&:hover': {
      '.form-table-actions-delete': {
        opacity: 1,
        transition: 'opacity .5s linear',
        visibility: 'visible',
      },
    },
    '.form-table-actions-delete': {
      opacity: 0,
      visibility: 'hidden',
    },
  },
}));

export const FormTableV2 = React.memo(
  function FormTableV2<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
    data: IFormTableData<TRow, TPath>
  ) {
    const apiRef = useGridApiRef();
    const { Modal: UnsaveChangesModal, onOpen: openUnsaveChangesModal } = useModal({
      Modal: UnsaveChangesModalComponent,
      onClose: () => {
        setUnsaveChangesModalContext(undefined);
      },
    });
    const openUnsaveChangesModalWithContext = (context: UnsaveChangesModalContext) => {
      setUnsaveChangesModalContext(context);
      openUnsaveChangesModal();
    };

    const [rowToDeleteIndex, setRowToDeleteIndex] = React.useState<number | undefined>();
    const [unsaveChangesModalContext, setUnsaveChangesModalContext] = React.useState<
      UnsaveChangesModalContext | undefined
    >();

    React.useEffect(() => {
      if (data.shouldAddNewRowOnInit) {
        const actualValuesOnCurrentPage = arrayData.getValues();
        const lastRow = actualValuesOnCurrentPage[actualValuesOnCurrentPage.length - 1];
        const rowToAdd = data.shouldAddNewRowOnInit.newRow as InnerTRow<TRow, TPath>;
        rowToAdd.id = crypto.randomUUID();
        if (lastRow) {
          rowToAdd.index = lastRow.index + 1;
        }
        arrayData.append({ ...rowToAdd, hasBeenStored: false, id: crypto.randomUUID() }, data.pagination);
        data.shouldAddNewRowOnInit.onRowAdded();
      }
    }, []);

    const initialData = data.rows;
    const innerInitialData = initialData.map((row: TRow) => ({
      ...row,
      hasBeenStored: false,
      id: crypto.randomUUID(),
    }));

    const arrayData = useArrayForm<InnerTRow<TRow, TPath>, TPath>({
      apiRef: apiRef,
      defaultValues: innerInitialData,
    });

    const columns = createColumnsDefinition(data.columns, {
      arrayData: arrayData,
      currentPage: data.pagination.currentPage,
      formState: arrayData.getFormState(),
      getFormValue: (id: string) => arrayData.getValues().find((row) => row.id === id) as InnerTRow<TRow, TPath>,
      isDeleteActionDisabled:
        arrayData.getValues().filter((row) => {
          const rowData = row as unknown as IButtonRenderDataV2;
          return !rowData.isNew && !rowData.buttonNumber && !rowData.selectedOptions;
        }).length > 0,
      onRemoveNewlyAddedRow: (id: string) => {
        arrayData.remove(id);
        data.setRowCount((totalCount: number) => {
          return totalCount - 1;
        });
      },
      onRowClear: (index: number) => {
        const currentValue = arrayData.getValues()[index];
        const currentId = currentValue.id;
        const clearedValue = data.defaultRowValue(currentValue, true) as InnerTRow<TRow, TPath>;
        clearedValue.id = currentId;
        arrayData.update(clearedValue, false, true);
      },
      onRowDiscardChanges: (index: number) => arrayData.resetRow(index),
      onRowUpdate: (rowData) => {
        if (
          (rowToDeleteIndex === rowData.index || data.isRowIntendedForRemove(rowData)) &&
          areThereSomeUnsavedChanges({
            actualValues: arrayData.getValues(),
            formState: arrayData.getFormState(),
            ignoreChangesOnRowIndex: rowToDeleteIndex,
          })
        ) {
          openUnsaveChangesModalWithContext(UnsaveChangesModalContext.BeforeDeleteAnotherItem);
          return;
        }
        handleStoreRow(
          rowData,
          data.formActions.onRowUpdate,
          data.pagination.onPageChange,
          data.pagination.currentPage,
          arrayData.getDefaultValues(),
          arrayData.remove,
          arrayData.getValues,
          arrayData.update,
          data.setRowCount,
          data.formActions.onButtonsRefetch,
          rowToDeleteIndex,
          setRowToDeleteIndex
        );
      },
      rowToDeleteIndex,
      setRowToDeleteIndex,
    });

    return (
      <div key={`editableTable-${data.pagination.currentPage}-${data.pagination.currentPageSize}`}>
        <FormTableToolbar
          allRowsCount={data.toolbarOptions.totalButtonsCount || 0}
          onAddNewRow={() =>
            handleAddRow(
              arrayData.getFormState(),
              data.rowCount,
              data.pagination,
              data.defaultRowValue,
              arrayData.getValues(),
              arrayData.append,
              data.setRowCount,
              openUnsaveChangesModalWithContext,
              data.formActions
            )
          }
          addButtonDisabled={getIsDisableAddButton(
            data.toolbarOptions.initialTotalRowCount,
            data.toolbarOptions.maxTotalRowCount
          )}
          toolbarTexts={data.toolbarOptions.toolbarTexts}
        />
        <div id="editable-table" style={{ height: `calc(100vh - ${headerUnderTableHeight}px)`, minHeight: 215 }}>
          <StyledDataGridPro
            columns={columns}
            getRowHeight={() => 'auto'}
            disableVirtualization={false}
            rows={arrayData.getValues()}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableRowSelectionOnClick
            sortModel={[{ field: 'index', sort: 'asc' }]}
            hideFooter={false}
            pagination
            slotProps={{
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            paginationMode={'server'}
            paginationModel={{
              page: data.pagination.currentPage,
              pageSize: data.pagination.currentPageSize,
            }}
            onPaginationModelChange={(model) => {
              if (
                areThereSomeUnsavedChanges({
                  actualValues: arrayData.getValues(),
                  formState: arrayData.getFormState(),
                })
              ) {
                openUnsaveChangesModalWithContext(UnsaveChangesModalContext.BeforeSkipToNewPage);
                return;
              }
              if (model.page !== data.pagination.currentPage) {
                data.pagination.onPageChange(model.page);
              }
              if (model.pageSize !== data.pagination.currentPageSize) {
                data.pagination.onPageSizeChange(model.pageSize);
              }
            }}
            getRowId={(row) => row.id}
            apiRef={apiRef}
            getRowClassName={(params) => {
              const rowData = params.row as InnerTRow<TRow, TPath>;
              return getRowStyle(rowData.hasBeenStored, (rowData.dirtyFields?.length ?? 0) > 0, rowData.isNew);
            }}
            rowCount={data.rowCount}
          />
        </div>
        <UnsaveChangesModal context={unsaveChangesModalContext} />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.pagination.currentPage === nextProps.pagination.currentPage &&
      prevProps.rowCount === nextProps.rowCount &&
      prevProps.toolbarOptions.totalButtonsCount === nextProps.toolbarOptions.totalButtonsCount &&
      prevProps.pagination.currentPageSize === nextProps.pagination.currentPageSize
    );
  }
);

function getRowStyle(hasBeenStored?: boolean, isDirty?: boolean, isNewRow?: boolean) {
  if (hasBeenStored) {
    return 'dataGrid-form-row-saved';
  }
  if (isDirty || isNewRow) {
    return 'dataGrid-form-row-updated';
  }

  return '';
}

function handleAddRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  formState: IFormState,
  rowCount: number,
  pagination: IPagination<TRow, TPath>,
  defaultRowValue: (rowData?: TRow, shouldClear?: boolean, lastStoredRow?: TRow) => TRow,
  actualValues: InnerTRow<TRow, TPath>[],
  append: (value: TRow, pagination: IPagination<TRow, TPath>) => void,
  setRowCount: React.Dispatch<React.SetStateAction<number>>,
  openUnsaveChangesModalWithContext: (context: UnsaveChangesModalContext) => void,
  formActions: IFormActions<TRow, TPath>
) {
  if (actualValues.length + 1 > pagination.currentPageSize && areThereSomeUnsavedChanges({ actualValues, formState })) {
    openUnsaveChangesModalWithContext(UnsaveChangesModalContext.BeforeSkipToNewPage);
    return;
  }

  const targetPage = getTargetPage(rowCount, pagination.currentPageSize);

  if (pagination.currentPage !== targetPage) {
    setRowCount((count) => {
      return count + 1;
    });
    pagination.onPageChange(targetPage, {
      newRow: formActions.onCreateNewButtonOnNewPage(),
    });
    return;
  }

  const newRowData = defaultRowValue(actualValues[actualValues.length - 1]) as InnerTRow<TRow, TPath>;
  newRowData.id = crypto.randomUUID();
  append({ ...newRowData, id: crypto.randomUUID() }, pagination);
  setRowCount((count) => count + 1);
}

function handleStoreRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  rowData: InnerTRow<TRow, TPath>,
  onRowUpdate: (rowData: TRow, previousValue?: TRow) => Promise<IUpdateResult<TRow, TPath>>,
  onPageChange: (pageNumber: number, shouldAddNewRow?: IAddNewRowOnInit<TRow, TPath>) => void,
  currentPage: number,
  defaultRows: InnerTRow<TRow, TPath>[],
  remove: (id: string) => void,
  getValues: () => TRow[],
  update: (value: TRow, hasBeenStored?: boolean) => void,
  setRowCount: React.Dispatch<React.SetStateAction<number>>,
  onButtonsRefetch: () => void,
  rowToDeleteIndex: number | undefined,
  setRowToDeleteIndex: React.Dispatch<React.SetStateAction<number | undefined>>
) {
  update({ ...rowData, isLoading: true });
  onRowUpdate(
    rowData,
    defaultRows.find((row) => row.id === rowData.id)
  )
    .then((updateResult) => {
      const updatedData = updateResult.updatedData as InnerTRow<TRow, TPath>;
      if (updatedData === undefined) {
        remove(rowData.id);
        setRowToDeleteIndex(undefined);
        setRowCount((totalCount: number) => {
          return totalCount - 1;
        });

        if (currentPage > 0 && getValues().length === 0) {
          onPageChange(currentPage - 1);
        } else {
          onButtonsRefetch();
        }
        if (updateResult.onChangesStored) {
          updateResult.onChangesStored(getValues());
        }
        return;
      }

      update(updatedData, true);
      if (updateResult.onChangesStored) {
        updateResult.onChangesStored(getValues());
      }
    })
    .catch((_error) => {
      update({ ...rowData, isLoading: false });
      if (rowToDeleteIndex === rowData.index) {
        setRowToDeleteIndex(undefined);
      }
    });
}
