import { ButtonWithContactsResponse } from '../../../../../apiRtk/be4feApi';
import { IUpdateResult } from '../../../../../components/FormTableV2/data';
import { IIndexedRow, Primitive } from '../../../../../components/FormTableV2/hooks/useCustomForm';
import { IStateData } from '../hooks/useGetStateData';
import { IButtonRenderDataV2 } from '../IntercomV2Data';
import { getSipNumber, tryToGetFirstApartmentContactType, tryToGetFirstRingingGroupType } from './contactItemType';
import { getSelectedOptions } from './helpers';
import { getLocalCallsIds } from './localCalls';

export interface IProcessRowUpdateResultProps {
  rowData: IButtonRenderDataV2;
  result: ButtonWithContactsResponse | undefined;
  stateData: IStateData;
  onChangesStored: (values: IButtonRenderDataV2[]) => void;
}
export function processRowUpdateResult<
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>({ onChangesStored, result, rowData, stateData }: IProcessRowUpdateResultProps): IUpdateResult<TRow, TPath> {
  if (!result) {
    return {
      onChangesStored: (values) => onChangesStored(values as unknown as IButtonRenderDataV2[]),
      updatedData: undefined,
      updatedRowUid: rowData.id as string,
    };
  }

  const firstApartmentOrRingingGroup =
    tryToGetFirstRingingGroupType(result.contacts[0]?.contactItems) ||
    tryToGetFirstApartmentContactType(result.contacts[0]?.contactItems);

  const updatedData: IButtonRenderDataV2 = {
    ...rowData,
    buttonName: result.buttonConfigurationButtonName,
    externalId: result.linkingId,
    isLoading: false,
    isNew: false,
    isVirtual: undefined,
    ringingGroup: getSipNumber(firstApartmentOrRingingGroup),
    rowDataIds: {
      buttonConfigurationButtonId: result.buttonConfigurationButtonId,
      contactId: result.contacts[0]?.id,
      contactItemId: firstApartmentOrRingingGroup?.id,
      contactItemLocalCallIds: getLocalCallsIds(result.contacts[0]?.contactItems),
      displayButtonId: result.displayButtonId,
    },
    selectedOptions: getSelectedOptions(
      result.contacts,
      stateData.devicesChipsOptions,
      stateData.apartmentsChipsOptions
    ),
  };

  return {
    //TODO try to avoid type assignment
    onChangesStored: (values) => onChangesStored(values as unknown as IButtonRenderDataV2[]),
    updatedData: updatedData as unknown as TRow,
    updatedRowUid: rowData.id as string,
  };
}
