import {
  ButtonWithContactsResponse,
  CreateUpdateContactButtonsForDeviceApiArg,
  useCreateUpdateContactButtonsForDeviceMutation,
  useDeleteContactButtonsOfDeviceMutation,
} from '../../../../../../apiRtk/be4feApi';
import { getButtonName } from '../../helpers/helpers';
import { IButtonRenderDataV2 } from '../../IntercomV2Data';
import { IButtonRenderData, IStateData } from '../useGetStateData';
import { ContactItemRequest, getCallSettingsContactItems } from './callSettingsContactItems';
import { getButtonIndex as getButtonIndex, isRowIntendedForRemove } from './helper';

export interface IUseUpdateRow {
  updateRow: (
    rowData: IButtonRenderData,
    previousValue: IButtonRenderData,
    originalButtonData: ButtonWithContactsResponse | undefined
  ) => Promise<ButtonWithContactsResponse | undefined>;
}

export interface IUseUpdateRowProps {
  stateData: IStateData;
}
export function useUpdateRow({ stateData }: IUseUpdateRowProps): IUseUpdateRow {
  const [createUpdateContactButtons] = useCreateUpdateContactButtonsForDeviceMutation();
  const [deleteContactButtonsOfDevice] = useDeleteContactButtonsOfDeviceMutation();

  const updateRow = async (
    rowData: IButtonRenderDataV2,
    previousValue: IButtonRenderDataV2,
    originalButtonData: ButtonWithContactsResponse | undefined
  ): Promise<ButtonWithContactsResponse | undefined> => {
    try {
      if (isRowIntendedForRemove({ rowData })) {
        await deleteContactButtonsOfDevice({
          companyId: stateData.companyId,
          deviceId: stateData.device.id,
          linkingId: rowData.externalId!,
          siteId: stateData.siteId,
        }).unwrap();
        return undefined;
      }

      const contactItems = getCallSettingsContactItems({
        originalButtonDataContact: originalButtonData?.contacts[0],
        previousRowData: previousValue,
        rowData,
        stateData,
      });

      const result = await createUpdateContactButtons(
        getCreateUpdateContactButtonsForDeviceApiArg({
          contactItems: contactItems || [],
          originalButtonData,
          rowData,
          stateData,
        })
      ).unwrap();
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    updateRow,
  };
}

export function getCreateUpdateContactButtonsForDeviceApiArg({
  contactItems,
  originalButtonData,
  rowData,
  stateData,
}: {
  rowData: IButtonRenderDataV2;
  contactItems: ContactItemRequest[];
  stateData: IStateData;
  originalButtonData: ButtonWithContactsResponse | undefined;
}): CreateUpdateContactButtonsForDeviceApiArg {
  const buttonIndex = getButtonIndex({ rowData });
  const buttonName = getButtonName(rowData.buttonNumber, rowData.buttonName, rowData.selectedOptions);
  const originalAdditionalContacts = originalButtonData?.contacts.slice(1);

  const payload: CreateUpdateContactButtonsForDeviceApiArg = {
    callSettingsRequest: {
      buttonConfigurationButton: {
        externalId: originalButtonData?.buttonConfigurationButtonExternalId,
        id: rowData.rowDataIds.buttonConfigurationButtonId,
        index: buttonIndex,
        name: buttonName,
      },
      contacts: [
        {
          contactItems,
          externalId: originalButtonData?.contacts[0]?.externalId,
          id: rowData.rowDataIds.contactId,
          name: buttonName,
          virtualNumber: originalButtonData?.contacts[0]?.virtualNumber,
        },
      ],
      displayButton: {
        externalId: originalButtonData?.displayButtonExternalId,
        id: rowData.rowDataIds.displayButtonId,
        name: buttonName,
      },
    },
    companyId: stateData.companyId,
    deviceId: stateData.device.id,
    siteId: stateData.siteId,
  };

  if (originalAdditionalContacts && originalAdditionalContacts.length) {
    payload.callSettingsRequest.contacts.push(
      ...originalAdditionalContacts.map((contact) => ({ ...contact, name: contact.name || '' }))
    );
  }

  return payload;
}
