import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSiteContext } from '../../../context/useSiteContext';
import { useGetActualCompanyId } from '../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../hooks/siteHooks';
import { selectors } from '../../../modules/sites';
import { getApartmentValidationSchema } from '../../../validations/apartmentFormValidationSchema';

export interface IApartmentFormProps {
  floor?: string;
  hasForm?: boolean;
  name?: string;
  number?: string;
  paidServicesActive?: boolean;
}

export function useGetApartmentFormData(initValues?: IApartmentFormProps) {
  const { formatMessage } = useIntl();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const licenceModel = useSelector(selectors.getSitesServiceMobileVideoLicenceModel);
  const isFloorRequired = useSiteContext().isSiteFeatureEnabled('MANDATORY_FLOOR');

  const formMethods = useForm<IApartmentFormProps>({
    defaultValues: {
      floor: '',
      hasForm: true,
      name: '',
      number: '',
      paidServicesActive: false,
    },
    mode: 'onChange',
    resolver: yupResolver<IApartmentFormProps>(
      Yup.object().shape({
        ...getApartmentValidationSchema(isFloorRequired, companyId, siteId, formatMessage, initValues),
        paidServicesActive: Yup.boolean(),
      })
    ),
  });

  return {
    companyId,
    formatMessage,
    formMethods,
    licenceModel,
    siteId,
  };
}
