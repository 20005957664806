import { Contact, LocalCallsContactItem, LocalCallsIntercomContactItemRequest } from '../../../../../apiRtk/be4feApi';
import { IButtonRenderDataV2, ILocalCallsContactItemIds } from '../IntercomV2Data';
import { IntercomContactItemType } from './contactItemType';

export function getLocalCallsIds(
  contactItems: Contact['contactItems'] | undefined
): ILocalCallsContactItemIds[] | undefined {
  const localCalls = contactItems?.filter((item) => item.type === IntercomContactItemType.LOCAL_CALLS);
  if (localCalls && localCalls.length) {
    return localCalls.map((item: LocalCallsContactItem) => ({
      //TODO is even possible that deviceConfigId is undefined?
      deviceConfigId: item.deviceConfigId || 0,
      id: item.id,
    }));
  }
  return undefined;
}

export function getLocalCallContactItemsRequest({
  originalButtonDataContact,
  rowData,
  selectedLocalCallsServiceIds,
}: {
  selectedLocalCallsServiceIds: number[] | undefined;
  rowData: IButtonRenderDataV2;
  originalButtonDataContact: Contact | undefined;
}): LocalCallsIntercomContactItemRequest[] | undefined {
  return selectedLocalCallsServiceIds?.map((localCallServiceId) => {
    const existingLocalCallsDefinitionId = rowData.rowDataIds.contactItemLocalCallIds?.find(
      (localCall) => localCall.deviceConfigId === localCallServiceId
    );

    if (existingLocalCallsDefinitionId) {
      const originalLocalCallsDefinition = originalButtonDataContact?.contactItems.find(
        (item) =>
          item.type === IntercomContactItemType.LOCAL_CALLS &&
          (item as LocalCallsContactItem).id === existingLocalCallsDefinitionId.id
      );
      return {
        deviceConfigId: localCallServiceId,
        externalId: originalLocalCallsDefinition?.externalId,
        id: existingLocalCallsDefinitionId.id,
        type: IntercomContactItemType.LOCAL_CALLS,
      };
    }

    return {
      deviceConfigId: localCallServiceId,
      type: IntercomContactItemType.LOCAL_CALLS,
    };
  });
}
