import MuiTextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { IIndexedRow, IUseArrayForm, Primitive } from '../hooks/useCustomForm';

export interface ICustomFormTextField<
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
> extends Omit<OutlinedTextFieldProps, 'variant'> {
  arrayData: IUseArrayForm<TRow, TPath> | undefined;
  fieldName: TPath;
  index: number;
  hasTopAndBottomMargin?: boolean;
  disableHelperText?: boolean;
  maxLength?: number;
}

export function CustomFormTextField<
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>({
  arrayData: arrayContext,
  disableHelperText,
  fieldName,
  hasTopAndBottomMargin: spaced,
  index,
  maxLength,
  sx,
  ...other
}: ICustomFormTextField<TRow, TPath>): React.JSX.Element {
  const context = arrayContext;
  const values = arrayContext?.getValues();
  let initialValue = '';

  if (values !== undefined && values.length > index) {
    initialValue = values[index][fieldName] as string;
  }

  const [value, setValue] = React.useState(initialValue);
  if (!isCorrectType(value)) {
    throw new Error('Invalid type');
  }

  React.useEffect(() => {
    context?.watchForInnerUpdatedRow(index, (data: TRow) => {
      setValue(data[fieldName] as string);
    });
  }, [index]);

  return (
    <MuiTextField
      {...other}
      key={fieldName.toString() + '-' + index}
      value={value}
      disabled={other.disabled}
      onChange={(e) => {
        if (maxLength !== undefined && e.target.value.length > maxLength) {
          return;
        }
        setValue(e.target.value);
        context?.changeValue(e.target.value, fieldName, index);
      }}
      onKeyDown={(e) => {
        // Prevent event to propagate into dataGrid more details https://stackoverflow.com/questions/71055614/why-is-the-space-key-being-filtered-out-by-muis-text-field-component
        e.stopPropagation();
      }}
      helperText={disableHelperText ? undefined : other.helperText || ' '}
      variant="outlined"
      sx={spaced ? { mb: 1, mt: 2 } : sx}
    />
  );
}

function isCorrectType(value: Primitive): value is string {
  return typeof value === 'string';
}
