import { isArrayEmpty } from '../../../../../../helpers/array';
import { IButtonRenderDataV2 } from '../../IntercomV2Data';
import { IButtonRenderData, IStateData } from '../useGetStateData';

export function getDeviceServicesIds({
  rowData,
  stateData,
}: {
  rowData: IButtonRenderData;
  stateData: IStateData;
}): { selectedMobileVideoIds: number[]; localCallsServiceIds: number[] } | undefined {
  const selectedDeviceIds = rowData.selectedOptions
    ?.filter((option) => option.optionKind === 'Devices')
    .map((option) => option.optionKey);

  if (selectedDeviceIds && selectedDeviceIds.length) {
    const selectedMobileVideoIds: number[] = [];
    const localCallsServiceIds: number[] = [];
    selectedDeviceIds.forEach((deviceId) => {
      const deviceInfo = stateData.devicesChipsOptions.find((device) => device.id === deviceId);
      if (deviceInfo?.services?.MOBILE_VIDEO?.id) {
        selectedMobileVideoIds.push(deviceInfo.services.MOBILE_VIDEO.id);
      }
      if (deviceInfo?.services?.LOCAL_CALLS) {
        localCallsServiceIds.push(deviceInfo.services.LOCAL_CALLS.id);
      }
    });

    return {
      localCallsServiceIds,
      selectedMobileVideoIds,
    };
  }

  return undefined;
}

export function getSelectedApartmentId({ rowData }: { rowData: IButtonRenderData }): number | undefined {
  return rowData.selectedOptions?.filter((option) => option.optionKind === 'Apartments')[0]?.optionKey;
}

export function getButtonIndex({ rowData }: { rowData: IButtonRenderData }): number {
  return rowData.buttonNumber === '' ? 1 : Number.parseInt(rowData.buttonNumber);
}

export function isRowIntendedForRemove({ rowData }: { rowData: IButtonRenderDataV2 }): boolean {
  return !!(!rowData.buttonName && isArrayEmpty(rowData.selectedOptions) && !rowData.isNew && rowData.externalId);
}
