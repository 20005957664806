import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import ApartmentAutocomplete from '../../../../components/ApartmentAutocomplete';
import ButtonLink from '../../../../components/ButtonLink';
import ChangeUserRoleDialog from '../../../../components/ChangeUserRoleModal';
import { SaveChangesBtn } from '../../../../components/SaveChangesBtn';
import UserChangePasswordDialog from '../../../../components/UserChangePasswordDialog';
import { UserInvitationResendButton } from '../../../../components/UserInvitationResendButton/UserInvitationResendButton';
import { FE_ROLES, FORM, PATHS } from '../../../../config/consts';
import { getUserApartments } from '../../../../helpers/userApartments';
import { useModal } from '../../../../hooks/useModal';
import { validateLocale } from '../../../../modules/localization/helpers';
import { actionsTS, CONTEXT } from '../../../../modules/user';
import { isUserGuest } from '../../../../modules/user/utils';
import AccessSecurityInfo from './AccessSecurityInfo';
import BasicInfo from './BasicInfo';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import { useGetUserInfoFormData } from './hooks/getUserInfoFormData';
import messages from './messages';
import PersonalInfo from './PersonalInfo';
import styles from './styles/UserInfoForm';
import UserLangSelect from './UserLangSelect';

function UserInfoForm() {
  const { dispatch, generalInfo, methods } = useGetGlobalHooks();
  const { siteLang } = generalInfo;
  const { apartmentsData, companyData, userData } = useGetUserInfoFormData();
  const { dirtyFields, isSubmitted, isValid } = methods.formState;
  const watchFields = methods.watch(['lang', 'role', 'phoneNumber', 'gsmCalls', 'apartment']);

  const initialFormData = {
    [FORM.HAS_FORM]: false,
    apartment: apartmentsData.apartmentOptions.filter((item) => userData.userModalData.apartments?.includes(item.id)),
    email: userData.userModalData.email,
    firstName: userData.userModalData.firstName,
    floor: '',
    gsmCalls: userData.userModalData.gsmCalls,
    lang: validateLocale(userData.userModalData.locale),
    lastName: userData.userModalData.lastName,
    name: '',
    number: '',
    phoneNumber: userData.userModalData.phoneNumber,
    role: userData.initialUserRole,
  };

  const {
    isOpen: isChangeUserRoleModalOpen,
    Modal: ChangeUserRoleModal,
    onOpen: onOpenChangeUserRoleModal,
  } = useModal({ Modal: ChangeUserRoleDialog });

  const { Modal: UserChangePasswordModal, onOpen: onOpenUserChangePasswordModal } = useModal({
    Modal: UserChangePasswordDialog,
  });

  useEffect(() => {
    methods.reset(initialFormData);
  }, [apartmentsData.initialApartments, userData.userModalData]);

  function handleSubmit(data) {
    const { floor, lang, name, number } = data;
    const apartments = data.apartment.filter((item) => item.id !== FORM.NEW_ITEM_ID).map((item) => item.id);

    dispatch(
      actionsTS.editSiteUser(CONTEXT.SITE_USERS, userData.isCurrentUser, {
        apartments,
        email: data.email,
        firstName: data.firstName,
        gsmCalls: data.gsmCalls,
        id: userData.user.id,
        lang: lang || validateLocale(userData.userModalData.locale),
        lastName: data.lastName,
        newApartment: data.hasForm ? { floor, name, number } : undefined,
        phoneNumber: data.phoneNumber,
        role: watchFields[1],
      })
    );
  }

  const updateFormData = ({ email, firstName, lastName }) => {
    methods.setValue('firstName', firstName, { shouldValidate: true });
    methods.setValue('lastName', lastName, { shouldValidate: true });
    methods.setValue('email', email, { shouldValidate: true });
  };

  const isGuest = isUserGuest(userData.initialUserRole);
  const isGuestOrLoginLess = isGuest || userData.user.role === FE_ROLES.LOGIN_LESS;

  return (
    <Paper sx={styles.root}>
      <Box sx={styles.infoForm}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Grid container spacing={4}>
              <Grid item sm={4} xs={12}>
                <Typography sx={styles.formTitle} variant="h6">
                  <FormattedMessage {...messages.personalInfoTitle} />
                </Typography>
                <PersonalInfo hasGSM={!isGuestOrLoginLess} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography sx={styles.formTitle} variant="h6">
                  <FormattedMessage {...messages.basicInfoTitle} />
                </Typography>
                {userData.initialUserRole && (
                  <BasicInfo
                    companyName={companyData.companyName}
                    control={methods.control}
                    currentUserRole={watchFields[1]}
                    initialUserRole={userData.initialUserRole}
                    onOpenChangeUserRoleModal={onOpenChangeUserRoleModal}
                    isGuest={isGuest}
                  />
                )}
                <Box sx={{ '& .MuiAutocomplete-root': { py: '0 !important' } }}>
                  <ApartmentAutocomplete
                    hasFormItemKey={FORM.HAS_FORM}
                    newItemId={FORM.NEW_ITEM_ID}
                    optionsData={apartmentsData.apartmentOptions}
                  />
                </Box>
                {userData.user.role !== FE_ROLES.LOGIN_LESS && !isGuest && (
                  <Grid item sx={{ mt: 2 }}>
                    <UserLangSelect
                      currentUserLang={userData.userModalData.locale}
                      selectedLang={watchFields[0]}
                      siteLang={siteLang}
                    />
                  </Grid>
                )}
                {watchFields[2] && watchFields[3] && watchFields[4].length === 0 && (
                  <Alert severity="warning">
                    <FormattedMessage {...messages.personalInfoAllowCallingWarning} />
                  </Alert>
                )}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography sx={styles.formTitle} variant="h6">
                  <FormattedMessage {...messages.accessAndSecurityTitle} />
                </Typography>
                <AccessSecurityInfo
                  canChangePassword={userData.isCurrentUser}
                  onShowEditPasswordModal={onOpenUserChangePasswordModal}
                  user={{
                    ...userData.user,
                    apartments: getUserApartments({
                      apartmentList: apartmentsData.apartmentOptions,
                      userAssignedApartmentsIds: userData.user.apartments,
                    }),
                  }}
                  isUserGuest={isGuest}
                />
                {!userData.user.enabled && userData.user.role !== FE_ROLES.LOGIN_LESS && (
                  <Box sx={{ mt: 2.25 }}>
                    <UserInvitationResendButton email={userData.user.email} />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item sx={styles.buttons} xs={12}>
                <ButtonLink
                  to={PATHS.SITE_USERS.replace(':companyId', userData.validation.companyId).replace(
                    ':siteId',
                    userData.validation.siteId
                  )}
                >
                  <FormattedMessage {...messages.buttonsCancel} />
                </ButtonLink>
                <SaveChangesBtn
                  isInline
                  disabled={!Object.keys(dirtyFields).length || !isValid || isSubmitted || isChangeUserRoleModalOpen}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <ChangeUserRoleModal
        onSetRole={(updated) => updateFormData(updated)}
        resetRole={() => methods.resetField('role')}
        values={{
          email: userData.userModalData.email,
          firstName: userData.userModalData.firstName,
          id: userData.user.id,
          isCurrentUser: userData.isCurrentUser,
          lastName: userData.userModalData.lastName,
        }}
      />
      <UserChangePasswordModal />
    </Paper>
  );
}

export default UserInfoForm;
