import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from '../components';
import { PATHS } from '../config/consts';
import AccessSettings from '../containers/DeviceDetail/AccessSettings';
import BasicDetail from '../containers/DeviceDetail/BasicDetail';
import CallSettings from '../containers/DeviceDetail/CallSettings';
import { DeviceDetail } from '../containers/DeviceDetail/DeviceDetail';
import Phonebooks from '../containers/DeviceDetail/Phonebooks';
import {
  showAccessSettings,
  showCallSettings,
  showPhonebooks,
  showSwitchCodes,
  showUnlockSettings,
} from '../containers/DeviceDetail/routeHelpers';
import SwitchCodes from '../containers/DeviceDetail/SwitchCodes';
import UnlockSettings from '../containers/DeviceDetail/UnlockSettings';
import { useUrlParams } from '../helpers/urlParams';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { getDeviceDetailRequest } from '../modules/devices/actions';
import { didInvalidDeviceDetail, getCurrentDeviceDetailSelector } from '../modules/devices/selectors';
import { PrivateRoute } from './components/PrivateRoute';

export function DeviceDetailRoutes() {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { deviceId } = useUrlParams();
  const { data, isFetching } = useSelector(getCurrentDeviceDetailSelector);
  const didInvalid = useSelector(didInvalidDeviceDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceDetailRequest(companyId, siteId, deviceId));
  }, [didInvalid]);

  if (isFetching) {
    return <Loader alignItems="center" />;
  }
  if (data === undefined) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <DeviceDetail />
          </PrivateRoute>
        }
      >
        <Route path={'detail'} element={<BasicDetail />} />
        {showAccessSettings(data) && <Route path={'access-settings'} element={<AccessSettings />} />}
        {showCallSettings(data) && <Route path={'call-settings'} element={<CallSettings />} />}
        {showPhonebooks(data) && <Route path={'phonebooks'} element={<Phonebooks />} />}
        {showSwitchCodes(data) && <Route path={'switch-codes'} element={<SwitchCodes />} />}
        {showUnlockSettings(data) && <Route path={'unlock-settings'} element={<UnlockSettings />} />}
      </Route>
      <Route path={'*'} element={<Navigate replace to={getFallbackRoute(companyId, siteId, deviceId)} />} />
    </Routes>
  );
}

function getFallbackRoute(companyId: number, siteId: number, deviceId?: number): string {
  if (deviceId !== undefined) {
    return PATHS.DEVICE_DETAIL.replace(':companyId', companyId.toString())
      .replace(':siteId', siteId.toString())
      .replace(':deviceId', deviceId?.toString());
  }

  return PATHS.DEVICES_OVERVIEW.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
}
