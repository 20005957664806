import { ApartmentContactItem, ContactItem, RingingGroupContactItem } from '../../../../../apiRtk/be4feApi';

export enum IntercomContactItemType {
  RINGING_GROUP = 'RINGING_GROUP',
  LOCAL_CALLS = 'LOCAL_CALLS',
  GSM_CALLS = 'GSM_CALLS',
  MOBILE_VIDEO = 'MOBILE_VIDEO',
  APARTMENT = 'APARTMENT',
}

export function isApartmentItem(contactItem: ContactItem | undefined): contactItem is ApartmentContactItem {
  return contactItem !== undefined && (contactItem as ApartmentContactItem).type === IntercomContactItemType.APARTMENT;
}

export function isRingingGroupItem(contactItem: ContactItem | undefined): contactItem is RingingGroupContactItem {
  return (
    contactItem !== undefined && (contactItem as RingingGroupContactItem).type === IntercomContactItemType.RINGING_GROUP
  );
}

export function getSipNumber(
  contactItem: ApartmentContactItem | RingingGroupContactItem | undefined
): string | undefined {
  if (isRingingGroupItem(contactItem) || isApartmentItem(contactItem)) {
    return contactItem.sipNumber;
  }
  return undefined;
}

export function tryToGetFirstApartmentContactType(
  contactItems: ContactItem[] | undefined
): ApartmentContactItem | undefined {
  return contactItems?.find((item) => isApartmentItem(item));
}
export function tryToGetFirstRingingGroupType(
  contactItems: ContactItem[] | undefined
): RingingGroupContactItem | undefined {
  return contactItems?.find((item) => isRingingGroupItem(item));
}
