import { defineMessages } from 'react-intl';

export default defineMessages({
  CallSettingsModalUnsavedChangesSubmit: {
    defaultMessage: 'OK',
    id: 'callSettings.modal.unsavedChanges.submit',
  },

  CallSettingsModalUnsavedChangesText: {
    defaultMessage: 'Save or discard your changes before moving to the next page.',
    id: 'callSettings.modal.unsavedChanges.text',
  },
  CallSettingsModalUnsavedChangesTextClear: {
    defaultMessage: 'Save or discard other changes before clearing this row, or they will be lost.',
    id: 'callSettings.modal.unsavedChanges.textClear',
  },
  CallSettingsModalUnsavedChangesTitle: {
    defaultMessage: 'Unsaved changes',
    id: 'callSettings.modal.unsavedChanges.title',
  },
});
