import { defineMessages } from 'react-intl';

export default defineMessages({
  addButton: {
    defaultMessage: 'Add more buttons',
    description: 'Add more buttons label',
    id: 'intercom.callSettingsListActions.addButton',
  },
  callSettingsButtonAddNewButton: {
    defaultMessage: 'Add new button',
    id: 'callSettings.button.addNewButton',
  },
  callSettingsButtonsCountAll: {
    defaultMessage: '{count, plural, one {button} other {buttons in total}}',
    id: 'callSettings.buttonsCountAll',
  },
  callSettingsButtonsCountAllTooltip: {
    defaultMessage: 'All buttons created on the intercom',
    id: 'callSettings.buttonsCountAll.tooltip',
  },
  callSettingsButtonsCountConfigured: {
    defaultMessage: '{count, plural, one {configured button} other {configured buttons}}',
    id: 'callSettings.buttonsCountConfigured',
  },
  callSettingsButtonsCountConfiguredTooltip: {
    defaultMessage: 'All buttons with filled call destination',
    id: 'callSettings.buttonsCountConfigured.tooltip',
  },
  doorStationHeader: {
    defaultMessage: 'Please use the Ring group number as the SIP address in you Door Station.',
    description: 'Door station header text',
    id: 'doorStationHeader.header',
  },
  labelButton: {
    defaultMessage: '#',
    description: 'Button index',
    id: 'listHeader.label.button',
  },
  labelButtonName: {
    defaultMessage: 'Button label',
    description: 'Button name label',
    id: 'listHeader.label.buttonName',
  },
  labelDevicesToCall: {
    defaultMessage: 'Call destination',
    description: 'Where button will call',
    id: 'listHeader.label.devicesToCall',
  },
  labelRingGroup: {
    defaultMessage: 'Ring group',
    description: 'Ring group label',
    id: 'listHeader.label.ringGroup',
  },
  manualWarningAlertWarning: {
    defaultMessage:
      'These call settings will NOT be uploaded into the intercom, as' +
      ' the Automatic configuration mode is turned off for this intercom. The settings' +
      ' below will affect only the configuration of the SIP proxy server. There will be' +
      ' a Ring group created on the SIP proxy server as to represent the group of devices' +
      ' assigned to the specific button. Please configure Directory and Buttons in your' +
      ' intercom manually using Ring Group numbers below.',
    description: 'Manual alert text',
    id: 'manualWarningAlert.text',
  },
  placeholderButtonName: {
    defaultMessage: 'Button name',
    description: 'Placeholder button name',
    id: 'placeholder.buttonName',
  },
});
