import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../../../config/consts';
import { useUrlParams } from '../../../../helpers/urlParams';
import { TId } from '../../../../modules/commonTypes';
import messages from '../messages';

function TooManyButtonsInfoBar({ currentSelectedDeviceId }: { currentSelectedDeviceId: TId }) {
  const { companyId, siteId } = useUrlParams();
  const [open, setOpen] = useState(true);

  return (
    <Box bgcolor="primary.create" my={2} p={2}>
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography color="primary.main" fontWeight={700} ml={1}>
          <FormattedMessage {...messages.addDeviceModalManyButtonsTitle} />
        </Typography>
        <IconButton onClick={() => setOpen(!open)} sx={{ m: 0, p: 0 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <Collapse in={open} timeout="auto">
        <Typography component="div" color="primary.main" mt={1} p={1} variant="body2">
          <FormattedMessage {...messages.addDeviceModalManyButtonsText} values={{ br: <br /> }} />
        </Typography>
        {companyId && siteId && (
          <Button
            href={PATHS.DEVICE_CALL_SETTINGS.replace(':companyId', companyId.toString())
              .replace(':siteId', siteId.toString())
              .replace(':deviceId', currentSelectedDeviceId.toString())}
            sx={{ m: 1 }}
            target="_blank"
            variant="outlined"
          >
            <FormattedMessage {...messages.addDeviceModalManyButtonsButton} />
          </Button>
        )}
      </Collapse>
    </Box>
  );
}

export default TooManyButtonsInfoBar;
