import { defineMessages } from 'react-intl';

export default defineMessages({
  addButtonDevice: {
    defaultMessage: 'Set in another device',
    id: 'addButtonDeviceButton',
  },
  addButtonRow: {
    defaultMessage: 'Add new button',
    id: 'addButtonRow.button',
  },
  addCallDestinationButtonApartment: {
    defaultMessage: 'Apartment',
    id: 'addCallDestinationButtonApartment',
  },
  addCallDestinationButtonDevice: {
    defaultMessage: 'Device',
    id: 'addCallDestinationButtonDevice',
  },
  addDeviceModalManyButtonsButton: {
    defaultMessage: 'Set call settings',
    id: 'addDeviceModal.manyButtons.button',
  },
  addDeviceModalManyButtonsText: {
    defaultMessage:
      'There is a large number of buttons on the selected intercom.{br}' +
      'At this point, the setup would be very inconvenient.\n' +
      '<p>Set up this device directly in the Call settings section.</p>',
    id: 'addDeviceModal.manyButtons.text',
  },
  addDeviceModalManyButtonsTitle: {
    defaultMessage: 'Too many buttons on this intercom',
    id: 'addDeviceModal.manyButtons.title',
  },
  formActionBackButton: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'formAction.backBtn',
  },
  formActionFinishedButton: {
    defaultMessage: 'Finished',
    id: 'action.finishedBtn',
  },
  formActionFinishLaterButton: {
    defaultMessage: 'Finish later',
    id: 'formAction.finishLaterBtn',
  },
  headerSettingsListButton: {
    defaultMessage: '#',
    id: 'headerSettingsList.button',
  },
  headerSettingsListButtonName: {
    defaultMessage: 'Button name',
    id: 'headerSettingsList.buttonName',
  },
  headerSettingsListCallDestinations: {
    defaultMessage: 'Call destinations',
    id: 'headerSettingsList.callDestinations',
  },
  intercomSelectLabel: {
    defaultMessage: 'Intercom',
    id: 'intercomSelect.label',
  },
  thirdStepEmptyFormAnsweringUnitContent: {
    defaultMessage:
      'Intercoms can only communicate with devices linked to their' +
      " buttons or displays. If you haven't added the intercom yet, be" +
      ' sure to link the answering unit after you do.',
    id: 'thirdStep.emptyForm.answeringUnit.content',
  },
  thirdStepEmptyFormAnsweringUnitHeading: {
    defaultMessage: 'For calls, link the answering unit with the intercom.',
    id: 'thirdStep.emptyForm.answeringUnit.heading',
  },
  thirdStepEmptyFormOtherDevicesContent: {
    defaultMessage:
      'Intercoms can only communicate with devices linked' +
      " to their buttons or displays. If you haven't added the intercom" +
      ' yet, be sure to link the smartphone, tablet, or answering unit after you do.',
    id: 'thirdStep.emptyForm.otherDevices.content',
  },
  thirdStepEmptyFormOtherDevicesHeading: {
    defaultMessage: 'For calls, link the smartphone,' + ' tablet, or answering unit with the intercom.',
    id: 'thirdStep.emptyForm.otherDevices.heading',
  },
  thirdStepEmptyFormSmartphoneContent: {
    defaultMessage:
      'Intercoms can only communicate with devices linked to their' +
      " buttons or displays. If you haven't added the intercom yet," +
      ' be sure to link the smartphone or tablet after you do.',
    id: 'thirdStep.emptyForm.smartphone.content',
  },
  thirdStepEmptyFormSmartphoneHeading: {
    defaultMessage: 'For calls, link the smartphone or tablet with the intercom.',
    id: 'thirdStep.emptyForm.smartphone.heading',
  },
  thirdStepFiveAndMoreCallSettings: {
    defaultMessage: 'call settings',
    id: 'thirdStep.fiveAndMore.callSettings',
  },
  thirdStepFiveAndMoreContent: {
    defaultMessage:
      'Your installation includes a large number of intercoms. We suggest you' +
      ' link the phone to the buttons or displays afterwards, in the {link}.',
    id: 'thirdStep.fiveAndMore.content',
  },
  thirdStepFiveAndMoreHeading: {
    defaultMessage: 'For calls, link the smartphone with buttons or displays on your' + ' intercom.',
    id: 'thirdStep.fiveAndMore.heading',
  },
  thirdStepFiveAndMoreHeadingTooltip: {
    defaultMessage: 'The intercom can only communicate with a device linked to the intercom' + ' buttons (or display).',
    id: 'thirdStep.fiveAndMore.headingTooltip',
  },
  thirdStepHeading: {
    defaultMessage:
      'For calls, link the smartphone with buttons or displays on your intercoms.' +
      ' If you have more than one intercom, remember to set the smartphone there as well.',
    id: 'thirdStep.default.heading',
  },
  thirdStepHeadingTooltip: {
    defaultMessage:
      'Your intercom can only communicate with a device linked to the intercom' +
      ' buttons (or display). If you haven’t added your intercom yet, make sure you link the' +
      ' phone to it afterwards.',
    id: 'thirdStep.default.headingTooltip',
  },
});
