import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { IChangeDependendValue } from '../data';
import { IIndexedRow, IUseArrayForm, Primitive } from '../hooks/useCustomForm';

export interface IAutoCompleteData<
  TOption extends Primitive,
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
> extends Omit<AutocompleteProps<TOption, true, true, false>, 'renderInput' | 'onChange' | 'onInputChange'> {
  arrayData: IUseArrayForm<TRow, TPath> | undefined;
  inputSettings: TextFieldProps;
  fieldName: TPath;
  index: number;
  options: TOption[];
  disableHelperText?: boolean;
  onChange?: (
    currentValue: TOption[],
    value: TOption[],
    rowData: TRow | undefined
  ) => { options: TOption[]; changeDependendValue: IChangeDependendValue<TPath> | undefined };
  inputAsFilter?: boolean;
}

const aggregatedDataGridStyle = () => ({
  [`& > .MuiInputBase-root:before`]: {
    borderBottom: 'none',
  },
});

export function Autocomplete<
  TOption extends Primitive,
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>({
  arrayData: arrayContext,
  disableHelperText,
  fieldName,
  index,
  inputAsFilter,
  inputSettings,
  onChange,
  options,
  ...other
}: IAutoCompleteData<TOption, TRow, TPath>) {
  const context = arrayContext;
  const values = context?.getValues();
  let initialValue: (TOption | string)[] = [];
  if (values !== undefined && values.length > index) {
    initialValue = values[index][fieldName] as (TOption | string)[];
  }
  const [currentValue, setValue] = React.useState(initialValue);
  if (context === undefined) {
    throw new Error('Incorrect form setup');
  }
  if (!isCorrectType<TOption>(currentValue)) {
    throw new Error('Invalid type');
  }

  React.useEffect(() => {
    context?.watchForInnerUpdatedRow(index, (data: TRow) => {
      setValue(data[fieldName] as (TOption | string)[]);
    });
  }, [index]);

  return (
    <MuiAutocomplete
      options={options}
      {...other}
      onChange={(_e, value) => {
        const onChangeResult = onChange !== undefined ? onChange(currentValue, value, values?.at(index)) : undefined;
        const updatedValue = onChangeResult ? onChangeResult.options : value;
        setValue(updatedValue);
        context.changeValue(updatedValue, fieldName, index);
        if (onChangeResult?.changeDependendValue !== undefined) {
          context.changeValue(
            onChangeResult.changeDependendValue.value,
            onChangeResult.changeDependendValue.field,
            index,
            true
          );
        }
      }}
      onInputChange={
        inputAsFilter
          ? undefined
          : (_, data) => {
              setValue([data]);
              context.changeValue(data, fieldName, index);
            }
      }
      value={currentValue}
      renderInput={(params) => {
        return (
          <TextField
            sx={aggregatedDataGridStyle}
            {...inputSettings}
            {...params}
            helperText={disableHelperText ? undefined : inputSettings.helperText || ' '}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...inputSettings?.InputLabelProps,
            }}
          />
        );
      }}
    />
  );
}

function isCorrectType<TOption extends Primitive>(value: Primitive): value is TOption[] {
  return Array.isArray(value);
}
