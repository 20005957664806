import AddBox from '@mui/icons-material/AddBox';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ListAlt from '@mui/icons-material/ListAlt';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import DataGridActionButton from '../../DataGridActionButton/DataGridActionButton';

export interface IFormTableToolbar {
  allRowsCount: number;
  configuredButtonsCount?: number;
  onAddNewRow: () => void;
  addButtonDisabled: boolean;
  toolbarTexts: IToolbarTexts;
}

export interface IToolbarTexts {
  totalCountLabel?: MessageDescriptor;
  totalCountTooltip?: MessageDescriptor;
  configuredCountLabel?: MessageDescriptor;
  configuredCountTooltip?: MessageDescriptor;
  addNewRowLabel?: MessageDescriptor;
}

export function FormTableToolbar({
  addButtonDisabled,
  allRowsCount,
  configuredButtonsCount: configuredRowsCount,
  onAddNewRow,
  toolbarTexts,
}: IFormTableToolbar) {
  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="space-between">
      <Stack direction="row" spacing={3}>
        <Stack alignItems="center" direction="row" display="flex" justifyContent="space-evenly" spacing={1}>
          <ListAlt color="primary" />
          <Typography textTransform="lowercase">
            <Typography color="primary" component="span" pr={0.5} variant="h6">
              {allRowsCount}
            </Typography>
            <FormattedMessage {...toolbarTexts.totalCountLabel} values={{ count: allRowsCount }} />
          </Typography>
          <Tooltip title={<FormattedMessage {...toolbarTexts.totalCountTooltip} />}>
            <InfoOutlined color="secondary" fontSize="small" />
          </Tooltip>
        </Stack>
        {configuredRowsCount !== undefined && (
          <Stack alignItems="center" direction="row" display="flex" justifyContent="space-evenly" spacing={1}>
            <ListAlt color="primary" />
            <Typography textTransform="lowercase">
              <Typography color="primary" component="span" pr={0.5} variant="h6">
                {configuredRowsCount}
              </Typography>
              <FormattedMessage {...toolbarTexts.configuredCountLabel} values={{ count: configuredRowsCount }} />
            </Typography>
            <Tooltip title={<FormattedMessage {...toolbarTexts.configuredCountTooltip} />}>
              <InfoOutlined color="secondary" fontSize="small" />
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <DataGridActionButton
        onClick={onAddNewRow}
        startIcon={<AddBox />}
        sx={{ minHeight: '100%' }}
        variant="outlined"
        disabled={addButtonDisabled}
      >
        <FormattedMessage {...toolbarTexts.addNewRowLabel} />
      </DataGridActionButton>
    </Stack>
  );
}
