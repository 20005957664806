import { Check, Close, RemoveCircle } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { isRowIntendedForRemove } from '../../containers/DeviceDetail/CallSettings/Intercom/hooks/useUpdateRow/helper';
import { IButtonRenderDataV2 } from '../../containers/DeviceDetail/CallSettings/Intercom/IntercomV2Data';
import { isArrayEmpty } from '../../helpers/array';
import { Autocomplete } from './components/Autocomplete';
import { CustomFormTextField } from './components/TextField';
import {
  IAutocompleteCell,
  ICellDefinition,
  INumberCell,
  isAutocompleteCell,
  isNumberCell,
  isStringCell,
  IStringCell,
} from './data';
import { IFormState, IIndexedRow, InnerTRow, IUseArrayForm, Primitive } from './hooks/useCustomForm';

export interface IRowActions<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  onRowUpdate: (rowData: InnerTRow<TRow, TPath>) => void;
  onRowDiscardChanges: (index: number) => void;
  onRowClear: (index: number) => void;
  onRemoveNewlyAddedRow: (id: string) => void;
  getFormValue: (id: string) => InnerTRow<TRow, TPath>;
  formState: IFormState;
  arrayData: IUseArrayForm<InnerTRow<TRow, TPath>, TPath>;
  currentPage: number;
  isDeleteActionDisabled: boolean;
  rowToDeleteIndex: number | undefined;
  setRowToDeleteIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export function createColumnsDefinition<
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>(cellDefinitions: ICellDefinition<TRow, TPath>[], formActions: IRowActions<TRow, TPath>) {
  const gridColumnDefinition: GridColDef<TRow>[] = [];

  cellDefinitions.forEach((cellDefinition) => {
    if (isNumberCell(cellDefinition)) {
      gridColumnDefinition.push(createNumberCell(cellDefinition, formActions));
    }
    if (isStringCell(cellDefinition)) {
      gridColumnDefinition.push(createStringCell(cellDefinition, formActions));
    }
    if (isAutocompleteCell(cellDefinition)) {
      gridColumnDefinition.push(createAutocompleteCell(cellDefinition, formActions));
    }
  });

  gridColumnDefinition.push(createActionsCell(formActions));

  return gridColumnDefinition;
}

function createNumberCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  cellDefinition: INumberCell<TRow, TPath>,
  formActions: IRowActions<TRow, TPath>
): GridColDef {
  return {
    field: cellDefinition.field as string,
    flex: cellDefinition.flex,
    headerName: cellDefinition.headerName,
    renderCell: (params: GridRenderCellParams) => {
      if (cellDefinition.editable) {
        return (
          <CustomFormTextField
            arrayData={formActions.arrayData}
            fieldName={cellDefinition.field}
            index={params.row.index}
            size="small"
            sx={{ pb: 1, pt: 1 }}
            disableHelperText
          />
        );
      }

      return cellDefinition.formatter !== undefined ? cellDefinition.formatter(params.value) : params.value;
    },
    sortable: cellDefinition.sortable,
    width: cellDefinition.width,
  };
}

function createStringCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  cellDefinition: IStringCell<TRow, TPath>,
  formActions: IRowActions<TRow, TPath>
): GridColDef {
  return {
    field: cellDefinition.field as string,
    flex: cellDefinition.flex,
    headerName: cellDefinition.headerName,
    renderCell: (params: GridRenderCellParams) => {
      if (cellDefinition.editable) {
        return (
          <CustomFormTextField
            arrayData={formActions.arrayData}
            fieldName={cellDefinition.field}
            index={params.row.index}
            size="small"
            sx={{ pb: 1, pt: 1 }}
            disableHelperText
            maxLength={cellDefinition.maxLength}
          />
        );
      }

      return params.value;
    },
    sortable: cellDefinition.sortable,
    width: cellDefinition.width,
  };
}

function createAutocompleteCell<
  TOption extends Primitive,
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>(cellDefinition: IAutocompleteCell<TOption, TRow, TPath>, formActions: IRowActions<TRow, TPath>): GridColDef {
  const {
    editable,
    field,
    flex,
    getIsOptionDisabled,
    headerName,
    inputPlaceholder,
    options,
    sortable,
    width,
    ...rest
  } = cellDefinition;

  return {
    field: field as string,
    flex: flex,
    headerName: headerName,
    renderCell: (params: GridRenderCellParams) => {
      const rowData = formActions.getFormValue(params.row.id);
      if (rowData === undefined) {
        return <></>;
      }
      if (editable) {
        return (
          <Autocomplete
            {...rest}
            arrayData={formActions.arrayData}
            fieldName={cellDefinition.field}
            index={params.row.index}
            inputSettings={{
              placeholder: inputPlaceholder !== undefined ? inputPlaceholder(rowData) : undefined,
              variant: 'standard',
            }}
            options={options}
            size="small"
            fullWidth
            sx={{ pb: 1, pt: 1 }}
            disableHelperText
            limitTags={2}
            onChange={cellDefinition.onChange}
            getOptionDisabled={
              getIsOptionDisabled !== undefined ? (option: TOption) => getIsOptionDisabled(option, rowData) : undefined
            }
            inputAsFilter
          />
        );
      }

      return params.value;
    },
    sortable: sortable,
    width: width,
  };
}

function createActionsCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  formActions: IRowActions<TRow, TPath>
): GridColDef {
  return {
    align: 'right',
    field: 'actions',
    headerName: '',
    renderCell: (params: GridRenderCellParams) => {
      const rowInnerData = params.row as InnerTRow<TRow, TPath>;
      const rowData = params.row as IButtonRenderDataV2;
      const hasUpdated = (rowInnerData.dirtyFields?.length ?? 0) > 0;
      const isLastButtonOnFirstPage = formActions.currentPage === 0 && rowInnerData.index === 0 && !rowData.buttonName;
      const isDeleteAlreadyActiveOnAnotherRow = !!(
        formActions.rowToDeleteIndex !== undefined && formActions.rowToDeleteIndex !== rowInnerData.index
      );
      const isDeleteActiveOnCurrentRow = formActions.rowToDeleteIndex === rowInnerData.index;
      const isDeleteButtonDisabled =
        formActions.isDeleteActionDisabled ||
        rowInnerData.isNew ||
        hasUpdated ||
        isLastButtonOnFirstPage ||
        isDeleteAlreadyActiveOnAnotherRow ||
        rowData.isVirtual;

      const isCheckButtonConfirmingDeleteDisabled =
        isRowIntendedForRemove({ rowData }) &&
        formActions.rowToDeleteIndex !== undefined &&
        formActions.rowToDeleteIndex !== rowInnerData.index;
      const isCheckButtonDisabled = isCheckButtonConfirmingDeleteDisabled;

      return (
        <div id={'row-' + rowInnerData.index}>
          <Stack flexDirection={'row'}>
            {(hasUpdated || rowInnerData.isNew) && (
              <>
                <IconButton
                  size="small"
                  disabled={isCheckButtonDisabled}
                  onClick={() => {
                    if (!rowInnerData.isLoading) {
                      const rowValues = formActions.getFormValue(rowInnerData.id);

                      return formActions.onRowUpdate(rowValues);
                    }
                  }}
                >
                  {rowInnerData.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Check fontSize="small" color={isCheckButtonDisabled ? 'disabled' : 'primary'} />
                  )}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (rowInnerData.isNew && isArrayEmpty(rowInnerData.dirtyFields)) {
                      formActions.onRemoveNewlyAddedRow(rowInnerData.id);
                    } else {
                      formActions.onRowDiscardChanges(rowInnerData.index);
                    }
                    if (formActions.rowToDeleteIndex === rowInnerData.index) {
                      formActions.setRowToDeleteIndex(undefined);
                    }
                  }}
                >
                  <Close fontSize="small" color="secondary" />
                </IconButton>
              </>
            )}
            <IconButton
              size="small"
              className="form-table-actions-delete"
              sx={{
                opacity: isDeleteActiveOnCurrentRow ? '1 !important' : 'auto',
                visibility: getDeleteButtonVisibility(isDeleteAlreadyActiveOnAnotherRow, isDeleteActiveOnCurrentRow),
              }}
              onClick={() => {
                formActions.onRowClear(rowInnerData.index);
                formActions.setRowToDeleteIndex(rowInnerData.index);
              }}
              disabled={isDeleteButtonDisabled}
            >
              <RemoveCircle fontSize="small" color={isDeleteButtonDisabled ? 'disabled' : 'secondary'} />
            </IconButton>
          </Stack>
        </div>
      );
    },
    sortable: false,
    width: 120,
  };
}

function getDeleteButtonVisibility(isDeleteAlreadyActiveOnAnotherRow: boolean, isDeleteActiveOnCurrentRow: boolean) {
  if (isDeleteActiveOnCurrentRow) {
    return 'visible !important';
  }
  if (isDeleteAlreadyActiveOnAnotherRow) {
    return 'hidden !important';
  }

  return 'visible';
}
