/* eslint-disable sort/destructuring-properties */
/* eslint-disable sort/object-properties */
import { api } from './api';
import {
  IEditCompanyArg,
  IEditCompanyResponse,
  IGetCompanyArg,
  IGetCompanyResponse,
} from './legacyApi/company/company';
import { IFinishRegistrationArg, IFinishRegistrationResponse } from './legacyApi/registration/finishRegistration';
import { IGetSiteApartmentsResponse, ISiteApartmentsArgs } from './legacyApi/sites/siteApartments';

export enum RequestMethod {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  GET = 'GET',
}

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ///middleware/api/my2n/registration-api/{id}
    finishRegistration: build.mutation<IFinishRegistrationResponse, IFinishRegistrationArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.POST,
        url: `/registration-api/${queryArg.id}`,
      }),
    }),
    // Get company https://my2nv2resources2.docs.apiary.io/#reference/company-sites/get-edit-and-delete-a-site/get
    getCompany: build.query<IGetCompanyResponse, IGetCompanyArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}`,
      }),
    }),
    // ut company https://my2nv2resources2.docs.apiary.io/#reference/company-sites/get-edit-and-delete-a-site/get
    editCompany: build.mutation<IEditCompanyResponse, IEditCompanyArg>({
      query: (queryArg) => ({
        body: queryArg.editCompanyRequest,
        method: RequestMethod.PUT,
        url: `/companies/${queryArg.companyId}`,
      }),
    }),

    getSiteApartments: build.query<IGetSiteApartmentsResponse, ISiteApartmentsArgs>({
      query: (queryArg) => {
        const params = [];
        if (queryArg.filter) {
          params.push(`filter=${queryArg.filter}`);
        }
        if (queryArg.limit) {
          params.push(`limit=${queryArg.limit}`);
        }
        if (queryArg.order) {
          params.push(`order=${queryArg.order}`);
        }
        if (queryArg.offset) {
          params.push(`offset=${queryArg.offset}`);
        }
        if (queryArg.number) {
          params.push(`number=${queryArg.number}`);
        }
        if (queryArg.floorName) {
          params.push(`floorName=${queryArg.floorName}`);
        }
        return {
          body: undefined,
          method: RequestMethod.GET,
          url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments?${params.join('&')}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as legacyFeApi };

export const { useGetSiteApartmentsQuery, useFinishRegistrationMutation, useGetCompanyQuery, useEditCompanyMutation } =
  injectedRtkApi;
