import { AutocompleteProps } from '@mui/material';
import { IToolbarTexts } from './components/FormTableToolbar';
import { IIndexedRow, Primitive } from './hooks/useCustomForm';

export interface IUpdateResult<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  updatedData: TRow | undefined;
  updatedRowUid?: string;
  onChangesStored?: (values: TRow[]) => void;
}

export interface IFormActions<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  onRowUpdate: (rowData: TRow, previousValue: TRow) => Promise<IUpdateResult<TRow, TPath>>;
  onButtonsRefetch: () => void;
  onCreateNewButtonOnNewPage: () => TRow;
}

export interface IAddNewRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  newRow: TRow;
}
export interface IAddNewRowOnInit<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>
  extends IAddNewRow<TRow, TPath> {
  onRowAdded: () => void;
}

export interface IFormTableData<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  rows: TRow[];
  columns: ICellDefinition<TRow, TPath>[];
  defaultRowValue: (rowData?: TRow, shouldClear?: boolean, lastStoredRow?: TRow) => TRow;
  isRowIntendedForRemove: (row: TRow) => boolean;
  formActions: IFormActions<TRow, TPath>;
  pagination: IPagination<TRow, TPath>;
  toolbarOptions: IToolbarOptions;
  shouldAddNewRowOnInit?: IAddNewRowOnInit<TRow, TPath>;
  invalidationParams?: object;
  rowCount: number;
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
}

export interface IToolbarOptions {
  initialTotalRowCount?: number;
  totalButtonsCount?: number;
  maxTotalRowCount?: number;
  toolbarTexts: IToolbarTexts;
}

export interface IPagination<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  currentPage: number;
  currentPageSize: number;
  onPageChange: (pageNumber: number, shouldAddNewRow?: IAddNewRow<TRow, TPath>) => void;
  onPageSizeChange: (pageSize: number) => void;
  totalRowCount: number;
}

export interface ICellDefinition<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  editable: boolean;
  field: TPath;
  headerName: string;
  sortable?: boolean;
  width?: number;
  flex?: number;
}

export interface INumberCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>
  extends ICellDefinition<TRow, TPath> {
  decimalPoints: number;
  formatter?: (value: number) => string;
}

export interface IStringCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>
  extends ICellDefinition<TRow, TPath> {
  maxLength: number;
}

export interface IChangeDependendValue<TPath> {
  field: TPath;
  value: Primitive;
}

export interface IAutocompleteCell<
  TOption extends Primitive,
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
> extends ICellDefinition<TRow, TPath>,
    Pick<
      AutocompleteProps<TOption, true, true, false>,
      'renderOption' | 'getOptionLabel' | 'renderTags' | 'isOptionEqualToValue' | 'groupBy'
    > {
  options: TOption[];
  multiple: true;
  freeSolo: false;
  inputPlaceholder?: (rowData: TRow) => string;
  onChange?: (
    currentValue: TOption[],
    value: TOption[],
    rowData: TRow | undefined
  ) => { options: TOption[]; changeDependendValue: IChangeDependendValue<TPath> | undefined };
  getIsOptionDisabled?: (option: TOption, rowData: TRow) => boolean;
}

export function isNumberCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  cellDefinition: ICellDefinition<TRow, TPath>
): cellDefinition is INumberCell<TRow, TPath> {
  return (cellDefinition as INumberCell<TRow, TPath>).decimalPoints !== undefined;
}

export function isStringCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  cellDefinition: ICellDefinition<TRow, TPath>
): cellDefinition is IStringCell<TRow, TPath> {
  return (cellDefinition as IStringCell<TRow, TPath>).maxLength !== undefined;
}

export function isAutocompleteCell<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  cellDefinition: ICellDefinition<TRow, TPath>
): cellDefinition is IAutocompleteCell<Primitive, TRow, TPath> {
  return (cellDefinition as IAutocompleteCell<Primitive, TRow, TPath>).options !== undefined;
}
