import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Paper } from '../../../components';
import { SaveChangesBtn } from '../../../components/SaveChangesBtn';
import SecurityCodeAlert from '../../../components/SecurityCodeAlert';
import {
  CONFIGURATION_MODE,
  CONNECTION_MODE,
  DEVICE_TYPE,
  SERVICE,
  VERIFICATION_STATUS,
} from '../../../config/devices';
import { useUrlParams } from '../../../helpers/urlParams';
import { useModal } from '../../../hooks/useModal';
import apartmentActions, { selectors as apartmentsSelectors } from '../../../modules/apartments';
import deviceActions, { selectors as devicesSelectors } from '../../../modules/devices';
import { isActiveService } from '../../../modules/devices/helpers/helpers';
import { selectors as sitesSelectors } from '../../../modules/sites';
import VerifyDeviceModal from '../../VerifyDeviceModal';
import AccessControlService from '../AccessControlService';
import DefaultPasswordNotification from '../DefaultPasswordNotification';
import { isOpenRcConfigurationDisabled } from '../helpers';
import MobileVideoService from '../MobileVideoService';
import RemoteConfigurationService from '../RemoteConfigurationService';
import VersionNotification from '../VersionNotification';
import DeviceDetailDeviceInfo from './DeviceDetailDeviceInfo';
import DeviceDetailMy2nSettings from './DeviceDetailMy2nSettings';
import DeviceDetailNetworkSettings from './DeviceDetailNetworkSettings';
import useReducerForm from './useReducerForm';

const BasicDetail = ({
  apartments,
  device,
  firmware,
  floors,
  isAcActivable,
  isApartmentActivable,
  isDeviceInvalid,
  isMvActivable,
  isRcActivable,
  onActivateRemoteConfiguration,
  onChangeMobileVideoStatus,
  onCreateServiceConfiguration,
  onEditDevice,
  onGetVersion,
  onLoadApartments,
  onLoadUsers,
  onOpenRCModal,
  onRenewMobileVideoConfiguration,
  onRenewRemoteConfiguration,
  onUpdateFeatureFaceTracking,
  onUpdateFirmware,
  onUpdateService,
  onVerify,
  site,
  users,
}) => {
  const { companyId, deviceId, siteId } = useUrlParams();
  const { Modal: VerifyModal, onOpen: onOpenVerifyModal } = useModal({
    Modal: VerifyDeviceModal,
  });

  useEffect(() => {
    onLoadApartments(companyId, siteId);
    onLoadUsers(companyId, siteId, deviceId);
  }, [companyId, siteId, deviceId]);

  useEffect(() => {
    if (isDeviceInvalid) {
      onLoadApartments(companyId, siteId);
      onLoadUsers(companyId, siteId, deviceId);
    }
  }, [isDeviceInvalid]);

  useEffect(() => {
    if (deviceId === device?.id) {
      onGetVersion(device);
    }
  }, [device, deviceId]);

  const { disabled, handleChange, handleSubmit, values } = useReducerForm(onEditDevice, site, {
    apartmentId: device.apartmentId,
    configurationMode: isActiveService(device, SERVICE.ACS) ? CONFIGURATION_MODE.AUTOMATIC : CONFIGURATION_MODE.MANUAL,
    connectionMode: isActiveService(device, SERVICE.LOCAL_CALLS) ? CONNECTION_MODE.LOCAL : CONNECTION_MODE.CLOUD,
    formErrors: {},
    localCallsIdentifier: device?.services?.[SERVICE.LOCAL_CALLS]?.name,
    name: device.name,
    newApartmentFloor: '',
    newApartmentName: '',
    newApartmentNumber: '',
    type: device.type,
    userId: device.userId,
  });

  const isRCConfigurationDisabled = useMemo(
    () =>
      isOpenRcConfigurationDisabled(
        device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status || null,
        device.services?.[SERVICE.ACS]?.firmwareStatus || null
      ),
    [device.services?.[SERVICE.ACS]?.firmwareStatus, device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status]
  );

  return (
    <>
      <Paper tabContent>
        {device?.verificationStatus === VERIFICATION_STATUS.NOT_VERIFIED && (
          <SecurityCodeAlert
            companyId={device?.site?.company?.id}
            detail
            devices={[device]}
            onButtonClick={() => {
              onVerify(device), onOpenVerifyModal();
            }}
            siteId={device?.site?.id}
            type="verify"
          />
        )}
        {![DEVICE_TYPE.HIPMO, DEVICE_TYPE.THIRD_PARTY].includes(device.type) && (
          <Stack spacing={1} mb={4}>
            {device.hasDefaultPassword && (
              <DefaultPasswordNotification
                device={device}
                onOpenRCModal={onOpenRCModal}
                remoteConfigurationStatus={device?.services?.[SERVICE.REMOTE_CONFIGURATION].status}
                firmwareStatus={device?.services?.[SERVICE.ACS]?.firmwareStatus}
              />
            )}
            <VersionNotification
              adaptiveVideoQualityWarning={
                device?.services?.[SERVICE.MOBILE_VIDEO]?.adaptiveVideoQuality === true &&
                device?.services?.[SERVICE.MOBILE_VIDEO]?.adaptiveVideoQualitySupported === false
              }
              firmware={firmware.info ? firmware : undefined}
              firmwareStatus={device?.services?.[SERVICE.ACS]?.firmwareStatus}
              onUpdateFirmware={onUpdateFirmware}
            />
          </Stack>
        )}
        <Grid container direction="row">
          <Grid container item spacing={4}>
            <Grid item lg={4} md={6} xs={12}>
              <DeviceDetailDeviceInfo
                device={device}
                firmware={firmware}
                firmwareDisabled={!!firmware.isUpdating}
                onUpdateFirmware={onUpdateFirmware}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <DeviceDetailMy2nSettings
                apartments={apartments}
                device={device}
                floors={floors}
                isApartmentActivable={isApartmentActivable}
                onChange={handleChange}
                site={site}
                users={users}
                values={values}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <DeviceDetailNetworkSettings device={device} onChange={handleChange} values={values} />
            </Grid>
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Grid item>
              <SaveChangesBtn disabled={disabled} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <MobileVideoService
        device={device}
        firmware={firmware?.info ? firmware : undefined}
        isMvActivable={isMvActivable}
        onChangeMobileVideoStatus={onChangeMobileVideoStatus}
        onCreateServiceConfiguration={onCreateServiceConfiguration}
        onRenewConfiguration={onRenewMobileVideoConfiguration}
        onUpdateService={onUpdateService}
        onUpdateFeatureFaceTracking={onUpdateFeatureFaceTracking}
        site={site}
      />
      <AccessControlService
        device={device}
        isAcActivable={isAcActivable}
        isRCConfigurationDisabled={isRCConfigurationDisabled}
        onCreateServiceConfiguration={onCreateServiceConfiguration}
        onOpenRCModal={onOpenRCModal}
        onUpdateServiceConfiguration={onUpdateService}
      />
      <RemoteConfigurationService
        device={device}
        isRcActivable={isRcActivable}
        isRCConfigurationDisabled={isRCConfigurationDisabled}
        onActivateRemoteConfiguration={onActivateRemoteConfiguration}
        onCreateServiceConfiguration={onCreateServiceConfiguration}
        onOpenRCModal={onOpenRCModal}
        onRenewRemoteConfiguration={onRenewRemoteConfiguration}
      />
      <VerifyModal />
    </>
  );
};

BasicDetail.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  device: PropTypes.shape({
    apartmentId: PropTypes.number,
    hasDefaultPassword: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        active: PropTypes.bool,
        firmware: PropTypes.string,
        firmwareStatus: PropTypes.string,
      }),
      [SERVICE.LOCAL_CALLS]: PropTypes.shape({
        active: PropTypes.bool,
        name: PropTypes.string,
      }),
      [SERVICE.REMOTE_CONFIGURATION]: PropTypes.shape({
        status: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    type: PropTypes.string,
    userId: PropTypes.number,
    verificationStatus: PropTypes.oneOf(Object.keys(VERIFICATION_STATUS)),
  }).isRequired,
  firmware: PropTypes.oneOfType([PropTypes.object]).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAcActivable: PropTypes.bool.isRequired,
  isApartmentActivable: PropTypes.bool.isRequired,
  isDeviceInvalid: PropTypes.bool.isRequired,
  isMvActivable: PropTypes.bool.isRequired,
  isRcActivable: PropTypes.bool.isRequired,
  onActivateRemoteConfiguration: PropTypes.func.isRequired,
  onChangeMobileVideoStatus: PropTypes.func.isRequired,
  onCreateServiceConfiguration: PropTypes.func.isRequired,
  onEditDevice: PropTypes.func.isRequired,
  onGetVersion: PropTypes.func.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
  onRenewMobileVideoConfiguration: PropTypes.func.isRequired,
  onRenewRemoteConfiguration: PropTypes.func.isRequired,
  onUpdateFeatureFaceTracking: PropTypes.func.isRequired,
  onUpdateFirmware: PropTypes.func.isRequired,
  onUpdateService: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  site: PropTypes.oneOfType([PropTypes.object]).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { data, didInvalid } = devicesSelectors.getCurrentDeviceDetailSelector(state);
  return {
    apartments: apartmentsSelectors.getApartmentsListGrouped(state),
    device: data,
    firmware: state.devices.firmware,
    floors: apartmentsSelectors.getFloors(state),
    isAcActivable: devicesSelectors.getIsAcActivable(state),
    isApartmentActivable: devicesSelectors.getIsApartmentActivable(state),
    isDeviceInvalid: didInvalid,
    isMvActivable: devicesSelectors.getIsMvActivable(state),
    isRcActivable: devicesSelectors.getIsRcActivable(state),
    site: sitesSelectors.getSiteData(state),
    users: devicesSelectors.getUsers(state),
  };
};

const mapDispatchToProps = {
  onActivateRemoteConfiguration: deviceActions.activateRemoteConfiguration,
  onChangeMobileVideoStatus: deviceActions.changeMobileVideoStatus,
  onCreateServiceConfiguration: deviceActions.createServiceConfiguration,
  onEditDevice: deviceActions.editDevice,
  onGetVersion: deviceActions.getFirmware,
  onLoadApartments: apartmentActions.getApartmentsList,
  onLoadUsers: deviceActions.loadUsers,
  onOpenRCModal: deviceActions.rcShowModal,
  onRenewMobileVideoConfiguration: deviceActions.renewMobileVideoConfiguration,
  onRenewRemoteConfiguration: deviceActions.renewRemoteConfiguration,
  onUpdateFeatureFaceTracking: deviceActions.saveFeatureFaceTracking,
  onUpdateFirmware: deviceActions.updateFirmware,
  onUpdateService: deviceActions.updateService,
  onVerify: deviceActions.verifyDeviceShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetail);
