import {
  ApartmentIntercomContactItemRequest,
  Contact,
  GsmCallsIntercomContactItemRequest,
  IntercomContactItemRequest,
  LocalCallsIntercomContactItemRequest,
  MobileVideoIntercomContactItemRequest,
  RingingGroupIntercomContactItemRequest,
} from '../../../../../../apiRtk/be4feApi';
import { isArrayEmpty } from '../../../../../../helpers/array';
import {
  IntercomContactItemType,
  tryToGetFirstApartmentContactType,
  tryToGetFirstRingingGroupType,
} from '../../helpers/contactItemType';
import { getLocalCallContactItemsRequest } from '../../helpers/localCalls';
import { IButtonRenderDataV2 } from '../../IntercomV2Data';
import { IStateData } from '../useGetStateData';
import { getDeviceServicesIds, getSelectedApartmentId } from './helper';

export type ContactItemRequest =
  | IntercomContactItemRequest
  | ApartmentIntercomContactItemRequest
  | GsmCallsIntercomContactItemRequest
  | LocalCallsIntercomContactItemRequest
  | MobileVideoIntercomContactItemRequest
  | RingingGroupIntercomContactItemRequest;

export interface IGetCallSettingsContactItemsProps {
  rowData: IButtonRenderDataV2;
  previousRowData: IButtonRenderDataV2;
  stateData: IStateData;
  originalButtonDataContact: Contact | undefined;
}

export function getCallSettingsContactItems({
  originalButtonDataContact,
  previousRowData,
  rowData,
  stateData,
}: IGetCallSettingsContactItemsProps): ContactItemRequest[] | undefined {
  const contactItemInfo = getContactItemInfo({ previousRowData, rowData, stateData });
  const hasTypeChanged = contactItemInfo.hasContactItemTypeChanged;

  if (!isArrayEmpty(contactItemInfo.selectedMobileVideoServiceIds)) {
    // add ringing group contact item
    const originalRingingGroupData = tryToGetFirstRingingGroupType(originalButtonDataContact?.contactItems);

    const ringingGroupContactItem: RingingGroupIntercomContactItemRequest = {
      externalId: hasTypeChanged ? undefined : originalRingingGroupData?.externalId,
      gsmFallback: hasTypeChanged ? undefined : originalRingingGroupData?.gsmFallback,
      id: hasTypeChanged ? undefined : rowData.rowDataIds.contactItemId,
      members: contactItemInfo.selectedMobileVideoServiceIds,
      type: IntercomContactItemType.RINGING_GROUP,
    };

    const contactItems = [ringingGroupContactItem];

    if (!isArrayEmpty(contactItemInfo.selectedLocalCallsServiceIds)) {
      // add local calls contact items
      const localCallsContactItems = getLocalCallContactItemsRequest({
        originalButtonDataContact,
        rowData,
        selectedLocalCallsServiceIds: contactItemInfo.selectedLocalCallsServiceIds,
      });

      if (localCallsContactItems && localCallsContactItems.length) {
        contactItems.push(...localCallsContactItems);
      }
    }
    return contactItems;
  }

  if (contactItemInfo.selectedApartmentId !== undefined) {
    // or add just apartment contact item
    const originalRingingGroupData = tryToGetFirstApartmentContactType(originalButtonDataContact?.contactItems);

    const apartmentContactItem: ApartmentIntercomContactItemRequest = {
      apartmentId: contactItemInfo.selectedApartmentId,
      externalId: hasTypeChanged ? undefined : originalRingingGroupData?.externalId,
      id: hasTypeChanged ? undefined : rowData.rowDataIds.contactItemId,
      type: IntercomContactItemType.APARTMENT,
    };
    return [apartmentContactItem];
  }
  return undefined;
}

interface IGetContactItemsInfoProps {
  rowData: IButtonRenderDataV2;
  previousRowData: IButtonRenderDataV2;
  stateData: IStateData;
}

interface IContactItemsInfo {
  selectedApartmentId: number | undefined;
  selectedMobileVideoServiceIds: number[] | undefined;
  selectedLocalCallsServiceIds: number[] | undefined;
  hasContactItemTypeChanged: boolean;
}

export function getContactItemInfo({
  previousRowData,
  rowData,
  stateData,
}: IGetContactItemsInfoProps): IContactItemsInfo {
  const selectedServiceIds = getDeviceServicesIds({
    rowData,
    stateData,
  });

  const previousSelectedMobileVideoServiceIds = getDeviceServicesIds({
    rowData: previousRowData,
    stateData,
  })?.selectedMobileVideoIds;

  const apartmentId = getSelectedApartmentId({ rowData });
  const previousApartmentId = getSelectedApartmentId({ rowData: previousRowData });
  let hasContactItemTypeChanged = false;

  //TODO stateData to have isNew from interface
  if (!rowData.isNew) {
    // from device to apartment
    if (apartmentId && previousSelectedMobileVideoServiceIds) {
      hasContactItemTypeChanged = true;
    }
    //from apartment to device
    if (selectedServiceIds?.selectedMobileVideoIds && previousApartmentId) {
      hasContactItemTypeChanged = true;
    }
  }

  return {
    hasContactItemTypeChanged,
    selectedApartmentId: apartmentId,
    selectedLocalCallsServiceIds: selectedServiceIds?.localCallsServiceIds,
    selectedMobileVideoServiceIds: selectedServiceIds?.selectedMobileVideoIds,
  };
}
