/* eslint-disable sort/object-properties */
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { TId } from '../../../modules/commonTypes';
import { getNewApartmentValidationSchema, INewApartmentForm } from '../components/NewApartmentForm/validationSchema';
import { getNewUserValidationSchema, INewUserForm } from '../components/UserSelectField/validationSchema';
import messages from './messages';

export interface IFormData extends INewUserForm, INewApartmentForm {
  name: string;
  user?: number;
}

export function getThirdPartyValidationSchema(formatMessage: IntlShape['formatMessage'], companyId: TId, siteId: TId) {
  return Yup.object().shape({
    existUser: Yup.boolean(),
    ...getNewUserValidationSchema(formatMessage, companyId, siteId),
    ...getNewApartmentValidationSchema(formatMessage, companyId, siteId, false, true),
    name: Yup.string()
      .required(formatMessage(messages.thirdPartyFormRequired))
      .max(50, formatMessage(messages.thirdPartyFormTooLong))
      .test({
        message: formatMessage(messages.thirdPartyFormNameExist),
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
  });
}
