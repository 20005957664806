import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSiteContext } from '../../context/useSiteContext';
import { getSiteFloorsList } from '../../modules/apartments/actions';
import { getFloors } from '../../modules/apartments/selectors';
import { selectors as companySelectors } from '../../modules/company';
import { selectors as siteSelectors } from '../../modules/sites';
import { Autocomplete } from '../HookFormFields/Autocomplete';
import TextField from '../HookFormFields/TextField';
import messages from './messages';

function ApartmentForm({ apartmentFormStyle = {}, control }) {
  const companyId = useSelector(companySelectors.getCompanyId);
  const siteId = useSelector(siteSelectors.getSiteId);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const floors = useSelector(getFloors);
  const isFloorRequired = useSiteContext().isSiteFeatureEnabled('MANDATORY_FLOOR');

  useEffect(() => {
    dispatch(getSiteFloorsList(companyId, siteId));
  }, []);

  return (
    <Stack direction="column" spacing={2} sx={{ ...apartmentFormStyle, bgcolor: 'background.default', p: 2 }}>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          autoFocus={true}
          control={control}
          disableClearable
          freeSolo
          fullWidth
          id="access-group-apartmentAdd-select"
          inputSettings={{
            autoFocus: true,
            helperText: formatMessage(messages.ApartmentFormHelperTextFloor),
            InputLabelProps: {
              shrink: true,
            },
            label: formatMessage(messages.ApartmentFormFieldFloor),
            required: isFloorRequired,
          }}
          name="floor"
          options={floors.map((floor) => floor.name)}
        />
        <TextField
          fullWidth
          helperText={formatMessage(messages.ApartmentFormHelperTextNumber)}
          label={formatMessage(messages.ApartmentFormFieldNumber)}
          name="number"
          required
        />
      </Stack>
      <TextField
        helperText={formatMessage(messages.ApartmentFormHelperTextName)}
        label={formatMessage(messages.ApartmentFormFieldName)}
        name="name"
      />
    </Stack>
  );
}

ApartmentForm.propTypes = {
  apartmentFormStyle: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
};

export default ApartmentForm;
