import { ButtonsWithContactsResponse } from '../../../../../apiRtk/be4feApi';
import { isArrayEmpty } from '../../../../../helpers/array';
import { IStateData } from '../hooks/useGetStateData';
import { IButtonRenderDataV2 } from '../IntercomV2Data';
import { getSipNumber, tryToGetFirstApartmentContactType, tryToGetFirstRingingGroupType } from './contactItemType';
import { DefaultButtonsCount, getSelectedOptions } from './helpers';
import { getLocalCallsIds } from './localCalls';

export function getButtonsRenderedData({
  buttons,
  currentPage,
  maxButtonIndex,
  pageSize,
  rowCount,
  setRowCount,
  stateData,
}: {
  buttons: ButtonsWithContactsResponse;
  stateData: IStateData;
  maxButtonIndex?: number;
  pageSize: number;
  currentPage: number;
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
  rowCount: number;
}): IButtonRenderDataV2[] {
  const updatedButtonsRenderData: IButtonRenderDataV2[] = [];

  const lastIndex = getLastIndex({ buttons, currentPage, maxButtonIndex, pageSize, rowCount, setRowCount });

  for (let i = 0; i < lastIndex; ++i) {
    const buttonIdx = currentPage! * pageSize! + i + 1;
    const buttonData = buttons.buttons.find((o) => o.idx === buttonIdx);
    if (!buttonData) {
      updatedButtonsRenderData.push(getDefaultVirtualButtonData(buttonIdx.toString(), i));
    } else {
      const firstApartmentOrRingingGroup =
        tryToGetFirstRingingGroupType(buttonData.contacts[0]?.contactItems) ||
        tryToGetFirstApartmentContactType(buttonData.contacts[0]?.contactItems);

      updatedButtonsRenderData.push({
        buttonName: buttonData.buttonConfigurationButtonName || '',
        buttonNumber: buttonData.idx?.toString() ?? '1',
        externalId: buttonData.linkingId,
        index: i,
        isVirtual: undefined,
        ringingGroup: getSipNumber(firstApartmentOrRingingGroup),
        rowDataIds: {
          buttonConfigurationButtonId: buttonData.buttonConfigurationButtonId,
          contactId: buttonData.contacts[0]?.id,
          contactItemId: firstApartmentOrRingingGroup?.id,
          contactItemLocalCallIds: getLocalCallsIds(buttonData.contacts[0]?.contactItems),
          displayButtonId: buttonData.displayButtonId,
        },
        selectedOptions: getSelectedOptions(
          buttonData.contacts,
          stateData.devicesChipsOptions,
          stateData.apartmentsChipsOptions
        ),
      });
    }
  }
  return updatedButtonsRenderData;
}

export function getLastIndex({
  buttons,
  currentPage,
  maxButtonIndex,
  pageSize,
  rowCount,
  setRowCount,
}: {
  buttons: ButtonsWithContactsResponse;
  maxButtonIndex?: number;
  pageSize: number;
  currentPage: number;
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
  rowCount: number;
}): number {
  let lastIndex = 0;
  if (isArrayEmpty(buttons.buttons) && maxButtonIndex) {
    // whole page is empty but there is some button on another higher page
    if (pageSize * currentPage < maxButtonIndex) {
      lastIndex = pageSize;
    }
  } else if (!isArrayEmpty(buttons.buttons)) {
    const maxIdx = Math.max(...buttons.buttons.map((o) => o.idx || 0));
    if (maxIdx < maxButtonIndex!) {
      lastIndex = pageSize;
    } else {
      lastIndex = maxIdx - pageSize * currentPage;
    }
  }
  // buttons are empty and maxButtonIndex is not defined and you are on the first page
  // create default first row
  else if (currentPage === 0) {
    if (rowCount !== DefaultButtonsCount) {
      setRowCount(DefaultButtonsCount);
    }
    lastIndex = DefaultButtonsCount;
  }
  return lastIndex;
}

export function getDefaultVirtualButtonData(idx: string, index: number): IButtonRenderDataV2 {
  return {
    buttonName: '',
    buttonNumber: idx,
    externalId: undefined,
    index: index,
    isVirtual: true,
    ringingGroup: undefined,
    rowDataIds: {
      buttonConfigurationButtonId: undefined,
      contactId: undefined,
      contactItemId: undefined,
      contactItemLocalCallIds: undefined,
      displayButtonId: undefined,
    },
    selectedOptions: [],
  };
}
