export function getTotalButtonsCount({
  totalButtonsInitialCount,
  totalButtonsOnCurrentPageInitialCount,
  totalNewRowsOnCurrentPage,
  totalRowsOnCurrentPage,
  totalVirtualButtonsOnCurrentPage,
}: {
  totalButtonsInitialCount: number;
  totalButtonsOnCurrentPageInitialCount: number;
  totalRowsOnCurrentPage: number;
  totalNewRowsOnCurrentPage: number;
  totalVirtualButtonsOnCurrentPage: number;
}): number {
  const buttonsOnCurrentPage = totalRowsOnCurrentPage - totalNewRowsOnCurrentPage - totalVirtualButtonsOnCurrentPage;
  const newButtonsOnCurrentPage = buttonsOnCurrentPage - totalButtonsOnCurrentPageInitialCount;
  return totalButtonsInitialCount + newButtonsOnCurrentPage;
}
