import { isEqual, isObject, transform } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSiteContext } from '../../../context/useSiteContext';
import { selectors as apartmentsSelectors } from '../../../modules/apartments';
import {
  checkApartmentFormBeforeSubmit,
  checkApartmentFormValidations,
  hasError,
} from './NewApartmentForm/validations';

export default (onEditDevice, site, initialValues = {}) => {
  const apartments = useSelector(apartmentsSelectors.getApartmentsListGrouped);
  const [values, setValues] = useState(initialValues);
  const isFloorRequired = useSiteContext().isSiteFeatureEnabled('MANDATORY_FLOOR');

  const handleChange = ({ target }) => {
    setValues((prevState) => {
      const formErrors = checkApartmentFormValidations(prevState, target, site, apartments);

      return {
        ...prevState,
        [target.name]: target.value,
        formErrors,
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEqual(values, initialValues)) {
      if (values.apartmentId === 'createNewApartment') {
        const newApartmentErrors = checkApartmentFormBeforeSubmit(values, apartments, isFloorRequired);

        if (newApartmentErrors !== undefined) {
          handleChange({
            target: {
              name: 'formErrors',
              value: newApartmentErrors,
            },
          });
        }
      }

      if (objectSize(values.formErrors) === 0) {
        onEditDevice({
          ...(values.newApartmentName ? { newApartmentName: values.newApartmentName } : { newApartmentName: '' }),
          ...difference(values, initialValues),
        });
      }
    }
  };

  return {
    disabled: isEqual(values, initialValues) || hasError(values.formErrors),
    handleChange,
    handleSubmit,
    values,
  };
};

function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

function objectSize(arr) {
  var size = 0;
  // eslint-disable-next-line guard-for-in
  for (const key in arr) {
    // eslint-disable-next-line no-prototype-builtins
    if (arr.hasOwnProperty(key) && arr[key] !== undefined) {
      size++;
    }
  }
  return size;
}
