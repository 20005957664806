import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../HookFormFields/Checkbox';
import messages from './messages';

const AccessLogCheckbox = () => (
  <Stack
    alignItems="flex-start"
    direction="column"
    justifyContent="flex-start"
    sx={{
      '& .MuiCheckbox-root': {
        py: 0,
      },
      '& .MuiFormControlLabel-root': {
        mb: 0.5,
        mr: 0,
      },
      pb: 1,
      pt: 3,
    }}
  >
    <Checkbox
      label={
        <Typography variant="body1">
          <FormattedMessage {...messages.accessLogCheckboxLabel} />
        </Typography>
      }
      name="accessLog"
    />
    <Typography
      color="secondary"
      sx={{
        pl: 4,
      }}
      variant="caption"
    >
      <FormattedMessage {...messages.accessLogCheckboxInfo} />
    </Typography>
  </Stack>
);

export default AccessLogCheckbox;
