/* eslint-disable sort/object-properties */
import { GridApiPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { IPagination } from '../data';
import { areObjectsEqual } from '../formTableHelpers';

export interface IIndexedRow {
  index: number;
}

export interface IUniqueRow {
  id: string;
}

export interface IFormParams<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  defaultValues: InnerTRow<TRow, TPath>[];
  apiRef: React.MutableRefObject<GridApiPro>;
}

interface IRowState {
  dirtyFields?: string[];
  hasBeenStored?: boolean;
  isNew?: boolean;
  isLoading?: boolean;
}

export type InnerTRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> = TRow &
  IUniqueRow &
  IRowState;

export type Primitive = string | number | boolean | undefined | Primitive[] | object;

export interface IUseArrayForm<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  getFormState: () => IFormState;
  watchForInnerUpdatedRow: (index: number, onInnerUpdate: (newRowData: TRow) => void) => void;
  append: (row: TRow, pagination: IPagination<TRow, TPath>) => void;
  getValues: () => TRow[];
  remove: (id: string) => void;
  resetRow: (index: number) => void;
  update: (row: TRow, hasBeenStored?: boolean, hasBeenCleared?: boolean) => void;
  getDefaultValues: () => TRow[];
  changeValue: (value: Primitive, field: TPath, index: number, invalidateWatchers?: boolean) => void;
}

export interface IDirtyField {
  index: number;
  id: string;
  field: string;
}

export interface IStoredRows {
  id: string;
  timestamp: number;
}

export interface IFormState {
  dirtyFields: IDirtyField[];
  storedRows: IStoredRows[];
}

interface IRegisteredWatchers<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow> {
  registeredWatchers: { [index: number]: ((newRowData: TRow) => void)[] };
}

export function useArrayForm<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  params: IFormParams<TRow, TPath>
): IUseArrayForm<TRow, TPath> {
  const innerDefaultValues: InnerTRow<TRow, TPath>[] = params.defaultValues.sort((a, b) => a.index - b.index);
  const formValuesRef = React.useRef({ formValues: innerDefaultValues });
  const formStateRef = React.useRef({ dirtyFields: [], storedRows: [] } as IFormState);
  const defaultValuesRef = React.useRef({ defaultValues: innerDefaultValues });
  const registeredWatchers = React.useRef<IRegisteredWatchers<TRow, TPath>>({ registeredWatchers: {} });

  React.useEffect(() => {
    const interval: NodeJS.Timeout | undefined = setInterval(() => {
      formStateRef.current.storedRows.forEach((storedRow) => {
        const elapsedMilliseconds = Date.now() - storedRow.timestamp;
        if (elapsedMilliseconds > 3000) {
          const rows = formStateRef.current.storedRows.filter((row) => row.id !== storedRow.id);
          formStateRef.current.storedRows = rows;
          const rowStateCleared = formValuesRef.current.formValues.find((row) => row.id === storedRow.id);
          if (rowStateCleared !== undefined) {
            updateRowState(params.apiRef, rowStateCleared, formStateRef.current, formValuesRef.current.formValues);
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function revalidateFormState(
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean,
    invalidateWatchers?: boolean
  ) {
    formValuesRef.current.formValues = newFormValues;

    const currentRowData = formValuesRef.current.formValues.find((row) => row.id === updatedRowId);
    if (currentRowData === undefined) {
      if (updatedRowId) {
        formStateRef.current.dirtyFields = formStateRef.current.dirtyFields.filter(
          (dirtyField) => dirtyField.id !== updatedRowId
        );
      }
      return;
    }

    const defaultRowData = defaultValuesRef.current.defaultValues.find((row) => row.id === currentRowData.id);

    if (defaultRowData === undefined) {
      return;
    }

    if (markAsStored) {
      formStateRef.current.storedRows = [
        ...formStateRef.current.storedRows,
        { id: currentRowData.id, timestamp: Date.now() },
      ];
    }

    if (
      !areObjectsEqual(currentRowData, defaultRowData!, ['isNew', 'isLoading', 'rowDataIds', 'hasBeenStored'] as any)
    ) {
      if (formStateRef.current.dirtyFields.filter((dirtyField) => dirtyField.id === currentRowData.id).length === 0) {
        formStateRef.current = {
          ...formStateRef.current,
          dirtyFields: [
            ...formStateRef.current.dirtyFields,
            { field: '', index: currentRowData.index, id: currentRowData.id },
          ],
        };
      }
    } else {
      formStateRef.current.dirtyFields = formStateRef.current.dirtyFields.filter(
        (dirtyField) => dirtyField.id !== currentRowData.id
      );
    }

    if (hasBeenCleared || markAsStored || invalidateWatchers) {
      registeredWatchers.current.registeredWatchers[currentRowData.index]?.forEach((watcher) =>
        watcher(currentRowData)
      );
    }

    updateRowState(params.apiRef, currentRowData, formStateRef.current, formValuesRef.current.formValues);
  }

  return {
    getFormState: () => formStateRef.current,
    watchForInnerUpdatedRow(index, onInnerUpdate) {
      if (registeredWatchers.current.registeredWatchers[index] === undefined) {
        registeredWatchers.current.registeredWatchers[index] = [];
      }
      registeredWatchers.current.registeredWatchers[index].push(onInnerUpdate);
    },
    append: (row: TRow, pagination: IPagination<TRow, TPath>) =>
      append(
        row,
        formValuesRef.current.formValues,
        defaultValuesRef.current.defaultValues,
        revalidateFormState,
        (defaultValues) => (defaultValuesRef.current.defaultValues = defaultValues),
        params.apiRef,
        pagination
      ),
    getValues: () => getValues(formValuesRef.current.formValues, formStateRef.current),
    remove: (id: string) =>
      remove(
        id,
        formValuesRef.current.formValues,
        defaultValuesRef.current.defaultValues,
        revalidateFormState,
        (defaultValues) => (defaultValuesRef.current.defaultValues = defaultValues)
      ),
    resetRow: (index: number) =>
      resetRow(index, formValuesRef.current.formValues, defaultValuesRef.current.defaultValues, revalidateFormState),
    update: (row: InnerTRow<TRow, TPath>, hasBeenStored?: boolean, hasBeenCleared?: boolean) =>
      updateRow(
        row,
        formValuesRef.current.formValues,
        defaultValuesRef.current.defaultValues,
        revalidateFormState,
        (defaultValues) => (defaultValuesRef.current.defaultValues = defaultValues),
        hasBeenStored,
        hasBeenCleared
      ),
    getDefaultValues: () => defaultValuesRef.current.defaultValues as TRow[],
    changeValue: (value, field, index, invalidateWatchers) =>
      changeValue(value, field, index, formValuesRef.current.formValues, revalidateFormState, invalidateWatchers),
  };
}

function updateRowState<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  apiRef: React.MutableRefObject<GridApiPro>,
  rowData: InnerTRow<TRow, TPath>,
  formState: IFormState,
  _formValues: InnerTRow<TRow, TPath>[]
) {
  apiRef?.current?.updateRows([
    {
      ...rowData,
      dirtyFields: formState.dirtyFields.filter((field) => field.id === rowData.id).map((field) => field.field),
      hasBeenStored: formState.storedRows.some((row) => row.id === rowData.id),
    } as InnerTRow<TRow, TPath>,
  ]);
}

function changeValue<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  value: Primitive,
  field: TPath,
  index: number,
  formValues: InnerTRow<TRow, TPath>[],
  revalidateFormState: (
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean,
    invalidateWatchers?: boolean
  ) => void,
  invalidateWatchers?: boolean
) {
  const newFormValues = [...formValues];
  newFormValues[index] = {
    ...newFormValues[index],
    [field]: value,
  };
  revalidateFormState(newFormValues, newFormValues[index].id, undefined, undefined, invalidateWatchers);
}

function append<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  row: TRow,
  formValues: InnerTRow<TRow, TPath>[],
  defaultValues: InnerTRow<TRow, TPath>[],
  revalidateFormState: (
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean
  ) => void,
  setDefaultValues: (defaultValues: InnerTRow<TRow, TPath>[]) => void,
  apiRef: React.MutableRefObject<GridApiPro>,
  pagination: IPagination<TRow, TPath>
) {
  const newRow = {
    ...row,
    id: crypto.randomUUID(),
    isNew: true,
    hasBeenStored: false,
  } as InnerTRow<TRow, TPath>;
  const newFormValues = [...formValues, newRow].sort((a, b) => a.index - b.index);
  const newDefaultValues = [...defaultValues, newRow].sort((a, b) => a.index - b.index);

  setDefaultValues(newDefaultValues);
  revalidateFormState(newFormValues, newRow.id);
  setTimeout(
    () =>
      apiRef?.current?.scrollToIndexes({ rowIndex: row.index + pagination.currentPageSize * pagination.currentPage })
  );
}

function remove<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  id: string,
  formValues: InnerTRow<TRow, TPath>[],
  defaultValues: InnerTRow<TRow, TPath>[],
  revalidateFormState: (
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean
  ) => void,
  setDefaultValues: (defaultValues: InnerTRow<TRow, TPath>[]) => void
) {
  const deletedRow = formValues.find((row) => row.id === id);
  const newFormValues = formValues
    .filter((row) => row.id !== id)
    .map((row) => {
      if (deletedRow && row.index > deletedRow.index) {
        return { ...row, index: row.index - 1 };
      }

      return row;
    })
    .sort((a, b) => a.index - b.index);
  const newDefaultValues = defaultValues
    .filter((row) => row.id !== id)
    .map((row) => {
      if (deletedRow && row.index > deletedRow.index) {
        return { ...row, index: row.index - 1 };
      }

      return row;
    })
    .sort((a, b) => a.index - b.index);

  setDefaultValues(newDefaultValues);
  revalidateFormState(newFormValues, deletedRow?.id ?? '');
}

function updateRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  row: InnerTRow<TRow, TPath>,
  formValues: InnerTRow<TRow, TPath>[],
  defaultValues: InnerTRow<TRow, TPath>[],
  revalidateFormState: (
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean
  ) => void,
  setDefaultValues: (defaultValues: InnerTRow<TRow, TPath>[]) => void,
  hasBeenStored?: boolean,
  hasBeenCleared?: boolean
) {
  const newFormValues: InnerTRow<TRow, TPath>[] = formValues
    .map((value) => (value.id === row.id ? { ...row, id: value.id, isNew: false } : value))
    .sort((a, b) => a.index - b.index);

  if (hasBeenStored) {
    const newDefaultValues = defaultValues
      .map((value) => (value.id === row.id ? { ...row, id: value.id } : value))
      .sort((a, b) => a.index - b.index);

    setDefaultValues(newDefaultValues);
  }
  revalidateFormState(newFormValues, row.id, hasBeenStored, hasBeenCleared);
}

function resetRow<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  index: number,
  formValues: InnerTRow<TRow, TPath>[],
  defaultValues: InnerTRow<TRow, TPath>[],
  revalidateFormState: (
    newFormValues: InnerTRow<TRow, TPath>[],
    updatedRowId: string,
    markAsStored?: boolean,
    hasBeenCleared?: boolean
  ) => void
) {
  const clearedRow = formValues.find((row) => row.index === index);
  const newFormValues: InnerTRow<TRow, TPath>[] = formValues
    .map((value) => (value.index === index ? defaultValues.find((row) => row.index === index) ?? value : value))
    .sort((a, b) => a.index - b.index);
  revalidateFormState(newFormValues, clearedRow?.id ?? '', false, true);
}

function getValues<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>(
  formValues: InnerTRow<TRow, TPath>[],
  formState: IFormState
) {
  return formValues.map((row) => {
    const dirtyFields = formState.dirtyFields.find((dirtyField) => dirtyField.id === row.id);
    return {
      ...row,
      dirtyFields: dirtyFields ? [dirtyFields.field] : undefined,
      hasBeenStored: formState.storedRows.find((storedRow) => storedRow.id === row.id) !== undefined,
    };
  });
}
