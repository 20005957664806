import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetSiteBasicInfoListQuery } from '../../apiRtk/be4feApi';
import { PATHS } from '../../config/consts';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import commonActions, { selectors as commonSelectors } from '../../modules/common';
import { selectors as userSelectors } from '../../modules/user';
import Logo from './components/appLogo/Logo';
import CompanyMenu from './components/companyMenu/CompanyMenu';
import SidebarMobileButton from './components/sidebarMobileButton/SidebarMobileButton';
import { SiteMenu } from './components/siteMenu/SiteMenu';
import UserMenu from './components/userMenu/UserMenu';
import { NavbarLayout } from './NavbarLayout';

export interface INavbarProps {
  isVersionWarningDisplayed: boolean;
}

export function Navbar({ isVersionWarningDisplayed }: INavbarProps) {
  const dispatch = useDispatch();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const navigate = useNavigate();

  const companySwitcherInvalid = useSelector(commonSelectors.isDidInvalidCompanySwitcher);
  const user: { firstName: string; lastName: string } = useSelector(userSelectors.getCurrentUserSelector);

  const didInvalid = useSelector(commonSelectors.didInvalidSiteSwitcher);
  const siteMenuData = useGetSiteBasicInfoListQuery({ companyId: companyId ?? 0 }, { skip: companyId === undefined });

  useEffect(() => {
    if (!siteMenuData.isFetching && didInvalid && !siteMenuData.isUninitialized) {
      siteMenuData.refetch();
      dispatch(commonActions.resetSiteSwitcherInvalidation());
    }
  }, [companyId, didInvalid]);

  useEffect(() => {
    dispatch(commonActions.loadDataCompanySwitcher());
  }, []);

  useEffect(() => {
    if (companySwitcherInvalid) {
      dispatch(commonActions.loadDataCompanySwitcher());
    }
  }, [companySwitcherInvalid]);

  return (
    <NavbarLayout
      appIcon={<Logo />}
      companyMenu={<CompanyMenu active={Boolean(companyId && !siteId)} />}
      isVersionWarningDisplayed={isVersionWarningDisplayed}
      sidebarMobile={
        <SidebarMobileButton sitesList={siteMenuData.data} isVersionWarningDisplayed={isVersionWarningDisplayed} />
      }
      siteMenu={<SiteMenu sitesList={siteMenuData.data} />}
      userMenu={<UserMenu onLogout={() => navigate(PATHS.LOGOUT)} user={user} />}
    />
  );
}
