import { defineMessages } from 'react-intl';

export default defineMessages({
  AutocompleteChipsPlaceholderReadyToClear: {
    defaultMessage: 'Click save to clear this button',
    id: 'autocompleteChipsPlaceholderReadyToClear',
  },
  autocompleteGroupApartments: {
    defaultMessage: 'Apartments',
    description: 'Apartments',
    id: 'autocompleteGroupApartments',
  },
  autocompleteGroupDevices: {
    defaultMessage: 'Devices',
    description: 'Devices',
    id: 'autocompleteGroupDevices',
  },
  clearButton: {
    defaultMessage: 'Clear',
    description: 'Autocomplete clear button',
    id: 'autocompleteChipsClearButton',
  },
  placeholderAddDevice: {
    defaultMessage: 'Add device',
    description: 'Add device',
    id: 'autocompleteChipsPlaceholderAddDevice',
  },
  placeholderDeviceLimitReached: {
    defaultMessage: 'Device limit reached',
    description: 'There is limit of 10 devices per button',
    id: 'autocompleteChipsPlaceholderDeviceLimitReached',
  },
  placeholderEmpty: {
    defaultMessage: 'Add device / apartment',
    description: 'Add device / apartment',
    id: 'autocompleteChipsPlaceholderEmpty',
  },
  placeholderReadyToDelete: {
    defaultMessage: 'Click save to delete this button',
    id: 'autocompleteChipsPlaceholderReadyToDelete',
  },

  placeholderSwapApartment: {
    defaultMessage: 'Swap apartment',
    description: 'Swap apartment',
    id: 'autocompleteChipsPlaceholderSwapApartment',
  },
});
