import { IGetSiteApartmentsResponse } from '../../../../../apiRtk/legacyApi/sites/siteApartments';
import { IApartment } from '../../../../../modules/apartments/store';

export function getApartmentsListOrdered(apartments: IGetSiteApartmentsResponse | undefined): IApartment[] {
  return (
    apartments?.results
      .map(
        (apartment) =>
          ({
            floor: apartment.floor?.name,
            id: apartment.id,
            label: apartment.number + ' ' + apartment.name,
            name: apartment.name,
            number: apartment.number,
          }) as IApartment
      )
      .sort((a, b) => (a.floor > b.floor ? 1 : -1)) ?? []
  );
}
