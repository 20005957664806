import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { getApartmentValidationSchema } from '../../validations/apartmentFormValidationSchema';

function useApartmentFormValidationSchema(isFloorRequired, companyId, siteId) {
  const { formatMessage } = useIntl();

  return yup.object().shape(getApartmentValidationSchema(isFloorRequired, companyId, siteId, formatMessage));
}

export default useApartmentFormValidationSchema;
