import { IntlShape, MessageDescriptor } from 'react-intl';
import { ApartmentContactItem, Contact, RingingGroupContactItem } from '../../../../../apiRtk/be4feApi';
import { IApartment } from '../../../../../modules/apartments/store';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { IButtonRenderData, ICallSettingOption } from '../hooks/useGetStateData';
import { IButtonRenderDataV2, IRowDataIds } from '../IntercomV2Data';
import messages from './messages';
import { transformSelectedOption } from './renderHelpers';

export function handleChangeListItem(
  buttonRenderData: IButtonRenderData,
  currentStateData: IButtonRenderData[],
  stateSetter: (newList: IButtonRenderData[]) => void,
  dataUpdate?: ICallSettingOption[]
): void {
  const clonedStateData: IButtonRenderData[] = [];

  currentStateData.forEach((currentButtonData) => {
    if (currentButtonData.index === buttonRenderData.index) {
      const clonedButtonData = currentButtonData;

      if (dataUpdate === undefined || dataUpdate.length === 0) {
        clonedButtonData.selectedOptions = [];
      } else {
        if (dataUpdate.length === 1) {
          clonedButtonData.selectedOptions = dataUpdate;
        } else {
          if (dataUpdate[0].optionKind === 'Devices') {
            clonedButtonData.selectedOptions = dataUpdate;
          } else {
            const option = dataUpdate.find(
              (option) =>
                option.optionKey !== clonedButtonData.selectedOptions?.[0].optionKey &&
                option.optionKind === clonedButtonData.selectedOptions?.[0].optionKind
            );

            clonedButtonData.selectedOptions = option ? [option] : undefined;
          }
        }
      }

      clonedStateData.push(clonedButtonData);
    } else {
      clonedStateData.push(currentButtonData);
    }
  });

  stateSetter(clonedStateData);
}

export function getIsChipWarning(device: IDevice) {
  return device?.services?.MOBILE_VIDEO?.hasLicence === false && device?.services?.LOCAL_CALLS?.active === false;
}

export function isOptionDisabled(actualOption: ICallSettingOption, selectedOptions?: ICallSettingOption[]): boolean {
  if (selectedOptions === undefined || selectedOptions.length === 0) {
    return false;
  }

  if (selectedOptions.length === 10) {
    return true;
  }

  if (selectedOptions[0].optionKind === 'Devices' && actualOption.optionKind === 'Apartments') {
    return true;
  }

  if (selectedOptions[0].optionKind === 'Apartments' && actualOption.optionKind === 'Devices') {
    return true;
  }

  return false;
}

export function getButtonName(
  buttonIndex: string,
  buttonName?: string,
  selectedCallDestination?: ICallSettingOption[]
): string {
  if (buttonName !== undefined && buttonName !== '') {
    return buttonName;
  }

  if (selectedCallDestination === undefined || selectedCallDestination.length === 0) {
    return 'Button ' + buttonIndex;
  }

  if (selectedCallDestination[0].optionKind === 'Devices') {
    return 'Button ' + buttonIndex;
  }

  return selectedCallDestination[0].optionName ?? 'Button ' + buttonIndex;
}

export function getOptionGroupName(name: string, formatMessage: IntlShape['formatMessage']): string {
  if (`autocompleteGroup${name}` in messages) {
    return formatMessage(messages[`autocompleteGroup${name}` as keyof typeof messages]);
  }

  return name;
}

export function getSelectedOptions(contacts: Contact[], deviceOptions: IDevice[], apartmentOptions: IApartment[]) {
  const selectedOptions: ICallSettingOption[] = [];
  contacts.forEach((contact) => {
    contact.contactItems.forEach((contactItem) => {
      if ((contactItem as ApartmentContactItem).apartmentId !== undefined) {
        const apartment = apartmentOptions.find(
          (apartment) => apartment.id === (contactItem as ApartmentContactItem).apartmentId
        );
        if (apartment !== undefined) {
          selectedOptions.push(transformSelectedOption(apartment, false));
        }
      } else {
        (contactItem as RingingGroupContactItem).members?.forEach((deviceId) => {
          const device = deviceOptions.find((device) => device.services?.MOBILE_VIDEO?.id === deviceId);
          if (device !== undefined) {
            selectedOptions.push(transformSelectedOption(device, false));
          }
        });
      }
    });
  });

  return selectedOptions;
}

export function getPlaceholder(
  formatMessage: (desc: MessageDescriptor) => string,
  rowData?: IButtonRenderData
): string | undefined {
  if (
    (rowData?.selectedOptions === undefined || rowData?.selectedOptions.length === 0) &&
    rowData?.buttonName === '' &&
    rowData?.ringingGroup !== '' &&
    !rowData?.isVirtual
  ) {
    return formatMessage(messages.AutocompleteChipsPlaceholderReadyToClear);
  }

  if (rowData?.selectedOptions === undefined || rowData?.selectedOptions.length === 0) {
    return formatMessage(messages.placeholderEmpty);
  }

  if (rowData?.selectedOptions.length === 10) {
    return formatMessage(messages.placeholderDeviceLimitReached);
  }

  if (rowData.selectedOptions.length >= 1 && rowData.selectedOptions[0].optionKind === 'Devices') {
    return formatMessage(messages.placeholderAddDevice);
  }

  return formatMessage(messages.placeholderSwapApartment);
}

export function getChipColor(option: ICallSettingOption) {
  if (option.optionState !== undefined && option.optionState === 'warning') {
    return 'warning';
  }

  if (option.hasUpdated) {
    return 'primary';
  }

  return 'default';
}

export const FirstButtonNumber = '1';
export const DefaultButtonsCount = 1;

export function getDefaultValue(
  rowData?: IButtonRenderDataV2,
  shouldClear?: boolean,
  lastStoredRow?: IButtonRenderDataV2
): IButtonRenderDataV2 {
  if (rowData === undefined) {
    return {
      buttonName: '',
      buttonNumber: FirstButtonNumber,
      externalId: '',
      index: 0,
      isVirtual: undefined,
      ringingGroup: '',
      rowDataIds: getDefaultRowDataIds(),
      selectedOptions: [],
    };
  }
  if (shouldClear) {
    if (lastStoredRow !== undefined) {
      const lastUsedButtonNumber = parseInt(lastStoredRow.buttonNumber);
      return {
        buttonName: '',
        buttonNumber: (lastUsedButtonNumber + 1).toString(),
        externalId: '',
        index: lastStoredRow.index + 1,
        isVirtual: undefined,
        ringingGroup: '',
        rowDataIds: getDefaultRowDataIds(),
        selectedOptions: [],
      };
    }
    return {
      ...rowData,
      buttonName: '',
      selectedOptions: [],
    };
  }
  const lastUsedButtonNumber = parseInt(rowData.buttonNumber);
  return {
    buttonName: '',
    buttonNumber: (lastUsedButtonNumber + 1).toString(),
    externalId: '',
    index: rowData.index + 1,
    isVirtual: undefined,
    ringingGroup: '',
    rowDataIds: getDefaultRowDataIds(),
    selectedOptions: [],
  };
}

export function getIsDisableAddButton(definedButtons?: number, maxButtonCount?: number) {
  if (maxButtonCount !== undefined) {
    return maxButtonCount === 1 || (definedButtons ?? 0) >= maxButtonCount;
  }

  return false;
}

export function getDefaultRowDataIds(): IRowDataIds {
  return {
    buttonConfigurationButtonId: undefined,
    contactId: undefined,
    contactItemId: undefined,
    contactItemLocalCallIds: undefined,
    displayButtonId: undefined,
  };
}
