import { InfoOutlined, ListAlt } from '@mui/icons-material';
import { Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export function IntercomSkeleton() {
  const { formatMessage } = useIntl();
  const columns = [
    {
      field: 'buttonNumber',
      headerName: formatMessage(messages.labelButton),
      renderCell: () => {
        return <Skeleton animation="wave" height={25} width="80%" />;
      },
      width: 64,
    },
    {
      field: 'buttonName',
      headerName: formatMessage(messages.labelButtonName),
      renderCell: () => {
        return <Skeleton animation="wave" height={25} width="80%" />;
      },
      width: 200,
    },
    {
      field: 'ringingGroup',
      headerName: formatMessage(messages.labelRingGroup),
      renderCell: () => {
        return <Skeleton animation="wave" height={25} width="80%" />;
      },
      width: 160,
    },
    {
      field: 'selectedOptions',
      headerName: formatMessage(messages.labelDevicesToCall),
      renderCell: () => {
        return <Skeleton animation="wave" height={25} width="80%" />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: () => {
        return <Skeleton animation="wave" height={25} width="80%" />;
      },
      width: 120,
    },
  ];

  const rows = [
    { fake1: '', fake2: '', fake3: '', fake4: '', id: 1 },
    { fake1: '', fake2: '', fake3: '', fake4: '', id: 2 },
    { fake1: '', fake2: '', fake3: '', fake4: '', id: 3 },
    { fake1: '', fake2: '', fake3: '', fake4: '', id: 4 },
    { fake1: '', fake2: '', fake3: '', fake4: '', id: 5 },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Stack alignItems="center" direction="row" display="flex" justifyContent="space-evenly" spacing={1}>
            <ListAlt color="primary" />
            <Typography textTransform="lowercase">
              <Skeleton animation="wave" height={25} width={50} />
            </Typography>
            <Tooltip title={<FormattedMessage {...messages.callSettingsButtonsCountAllTooltip} />}>
              <InfoOutlined color="secondary" fontSize="small" />
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>
      <DataGridPro columns={columns} rows={rows} hideFooter />
    </>
  );
}
