import { IApartment } from '../../../../../modules/apartments/store';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { ICallSettingOption } from '../hooks/useGetStateData';

export function transformSelectedOption(selectedOption: IDevice | IApartment, hasUpdated: boolean) {
  return isApartment(selectedOption)
    ? transformApartmentOption(selectedOption, hasUpdated)
    : transformDeviceOption(selectedOption, hasUpdated);
}

function transformApartmentOption(apartment: IApartment, hasUpdated: boolean): ICallSettingOption {
  return {
    hasUpdated,
    optionIcon: {
      isNew: false,
      stringAsIcon: apartment.number,
    },
    optionKey: apartment.id,
    optionKind: 'Apartments',
    optionName: apartment.name,
  };
}

function transformDeviceOption(device: IDevice, hasUpdated: boolean): ICallSettingOption {
  return {
    hasUpdated,
    optionIcon: {
      deviceAsIcon: {
        deviceType: device.deviceType,
        type: device.type,
      },
      isNew: false,
    },
    optionKey: device.id,
    optionKind: 'Devices',
    optionName: device.name,
  };
}

function isApartment(selectedOptions: IDevice[] | IApartment | IDevice): selectedOptions is IApartment {
  return (selectedOptions as IApartment).number !== undefined;
}
