import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useCountContactButtonsForDeviceQuery, useListContactButtonsForDeviceQuery } from '../../../../apiRtk/be4feApi';
import { useGetSiteApartmentsQuery } from '../../../../apiRtk/legacyFeApi';
import { IAddNewRow } from '../../../../components/FormTableV2/data';
import { FormTableV2 } from '../../../../components/FormTableV2/FormTableV2';
import { IIndexedRow, Primitive } from '../../../../components/FormTableV2/hooks/useCustomForm';
import { getDeviceMaxButtonCount } from '../../../../modules/devices/helpers/callSettings/devicesCallSettingsHelper';
import { IDevice } from '../../../../modules/devices/store/deviceStore';
import { getTotalButtonsCount } from './helpers/buttonsCount';
import { getButtonsRenderedData, getDefaultVirtualButtonData } from './helpers/buttonsRenderedData';
import { DefaultButtonsCount, getDefaultValue } from './helpers/helpers';
import { createTableDefinition } from './helpers/intercomTableDefinition';
import { getApartmentsListOrdered } from './helpers/orderedApartments';
import { transformSelectedOption } from './helpers/renderHelpers';
import { processRowUpdateResult } from './helpers/rowUpdateResult';
import { useFetchData as useFetchApartmentList } from './hooks/useFetchData';
import { ICallSettingOption, useGetStateData } from './hooks/useGetStateData';
import { isRowIntendedForRemove } from './hooks/useUpdateRow/helper';
import { useUpdateRow } from './hooks/useUpdateRow/useUpdateRow';
import { IntercomSkeleton } from './IntercomSkeleton';
import { IButtonRenderDataV2 } from './IntercomV2Data';
import messages from './messages';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

export function IntercomV2<TRow extends IIndexedRow & { [key in TPath]: Primitive }, TPath extends keyof TRow>() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const stateData = useGetStateData();
  const [currentPage, setCurrentPage] = React.useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE);
  const [shouldAddNewRow, setShouldAddNewRow] = React.useState<IAddNewRow<TRow, TPath> | undefined>();
  const { updateRow } = useUpdateRow({ stateData });

  const {
    data: headerData,
    isFetching: isHeaderFetching,
    refetch: refetchContactButtonsCount,
  } = useCountContactButtonsForDeviceQuery(
    {
      companyId: stateData.companyId,
      deviceId: stateData.device.id,
      siteId: stateData.siteId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [rowCount, setRowCount] = React.useState(headerData?.maxIndex ?? DefaultButtonsCount);
  const [totalButtonsCount, setTotalButtonsCount] = React.useState(headerData?.count ?? 0);

  React.useEffect(() => {
    if (headerData) {
      if (!shouldAddNewRow) {
        setRowCount(headerData.maxIndex ?? 0);
      }
      setTotalButtonsCount(headerData.count ?? 0);
    }
  }, [headerData]);

  const {
    data: buttons,
    isFetching,
    refetch: refetchContactButtons,
  } = useListContactButtonsForDeviceQuery(
    {
      companyId: stateData.companyId,
      deviceId: stateData.device.id,
      limit: pageSize,
      minIndex: pageSize * currentPage + 1,
      siteId: stateData.siteId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: apartments, isFetching: isApartmentListFetching } = useGetSiteApartmentsQuery({
    companyId: stateData.companyId,
    siteId: stateData.siteId,
  });
  const orderedApartments = getApartmentsListOrdered(apartments);
  useFetchApartmentList(stateData.companyId, stateData.siteId, dispatch);
  const deviceMaxButtonCount = getDeviceMaxButtonCount(stateData.device);

  const tableDefinition = React.useMemo(() => {
    const parsedOptions: ICallSettingOption[] = [];
    stateData.devicesChipsOptions.forEach((deviceChipOption: IDevice) => {
      if (deviceChipOption.id !== stateData.device.id) {
        parsedOptions.push(transformSelectedOption(deviceChipOption, false));
      }
    });
    orderedApartments.forEach((apartment) => {
      parsedOptions.push(transformSelectedOption(apartment, false));
    });
    return createTableDefinition(formatMessage, parsedOptions);
  }, [orderedApartments.length, stateData.devicesChipsOptions.length, isApartmentListFetching, stateData.isFetching]);

  const buttonsRenderData: IButtonRenderDataV2[] = React.useMemo(() => {
    if (buttons === undefined || isFetching || isHeaderFetching) {
      return [];
    }
    return getButtonsRenderedData({
      buttons,
      currentPage,
      maxButtonIndex: headerData?.maxIndex,
      pageSize,
      rowCount,
      setRowCount,
      stateData,
    });
  }, [
    buttons?.buttons.length,
    isFetching,
    currentPage,
    pageSize,
    isApartmentListFetching,
    stateData.isFetching,
    isHeaderFetching,
  ]);

  if (stateData.isFetching || isFetching || isApartmentListFetching || isHeaderFetching) {
    return <IntercomSkeleton />;
  }
  return (
    <>
      <FormTableV2
        columns={tableDefinition}
        rows={buttonsRenderData}
        rowCount={rowCount}
        setRowCount={setRowCount}
        defaultRowValue={getDefaultValue}
        isRowIntendedForRemove={(rowData: IButtonRenderDataV2) => isRowIntendedForRemove({ rowData })}
        shouldAddNewRowOnInit={
          shouldAddNewRow
            ? {
                newRow: shouldAddNewRow.newRow,
                onRowAdded: () => {
                  setShouldAddNewRow(undefined);
                },
              }
            : undefined
        }
        formActions={{
          onButtonsRefetch: () => {
            refetchContactButtons();
            refetchContactButtonsCount();
          },
          onCreateNewButtonOnNewPage: () => {
            return getDefaultVirtualButtonData((rowCount + 1).toString(), 0);
          },
          onRowUpdate: async (rowData: IButtonRenderDataV2, previousValue: IButtonRenderDataV2) => {
            const originalButtonData = buttons?.buttons.find((button) => button.linkingId === rowData.externalId);
            const result = await updateRow(rowData, previousValue, originalButtonData);
            return processRowUpdateResult({
              onChangesStored: (values: IButtonRenderDataV2[]) => {
                setTotalButtonsCount(
                  getTotalButtonsCount({
                    totalButtonsInitialCount: headerData?.count || 0,
                    totalButtonsOnCurrentPageInitialCount: buttons?.buttons.length || 0,
                    totalNewRowsOnCurrentPage: values.filter((r) => r.isNew).length,
                    totalRowsOnCurrentPage: values.length,
                    totalVirtualButtonsOnCurrentPage: values.filter((r) => r.isVirtual).length,
                  })
                );
              },
              result,
              rowData,
              stateData,
            });
          },
        }}
        pagination={{
          currentPage: currentPage,
          currentPageSize: pageSize,
          onPageChange: (pageNumber: number, shouldAddNewRow?: IAddNewRow<TRow, TPath>) => {
            if (shouldAddNewRow) {
              setShouldAddNewRow(shouldAddNewRow);
            }
            refetchContactButtonsCount();
            setCurrentPage(pageNumber);
          },
          onPageSizeChange: (pageSize: number) => setPageSize(pageSize),
          totalRowCount: headerData?.maxIndex ?? DefaultButtonsCount,
        }}
        toolbarOptions={{
          initialTotalRowCount: headerData?.count,
          maxTotalRowCount: deviceMaxButtonCount,
          toolbarTexts: {
            addNewRowLabel: messages.callSettingsButtonAddNewButton,
            configuredCountLabel: messages.callSettingsButtonsCountConfigured,
            configuredCountTooltip: messages.callSettingsButtonsCountConfiguredTooltip,
            totalCountLabel: messages.callSettingsButtonsCountAll,
            totalCountTooltip: messages.callSettingsButtonsCountAllTooltip,
          },
          totalButtonsCount: totalButtonsCount,
        }}
      />
    </>
  );
}
