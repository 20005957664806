import { IFormState, IIndexedRow, InnerTRow, Primitive } from './hooks/useCustomForm';

export function areObjectsEqual<T extends object, K extends keyof T>(o: T, p: T, propertiesToSkip: K[] = []) {
  let i;
  const keysO: PropertyKey[] = Object.keys(o).sort();
  const keysP: PropertyKey[] = Object.keys(p).sort();

  if (keysO.length !== keysP.length) {
    return false;
  }
  if (keysO.join('') !== keysP.join('')) {
    return false;
  }

  for (i = 0; i < keysO.length; ++i) {
    const currentKeyA = o[keysO[i] as keyof T];
    const currentKeyB = p[keysO[i] as keyof T];

    // eslint-disable-next-line no-prototype-builtins
    if (!o.hasOwnProperty(keysO[i]) || propertiesToSkip.includes(keysO[i] as K)) {
      continue;
    }

    if (currentKeyA instanceof Array && currentKeyB instanceof Array) {
      if (!areArraysEqual(currentKeyA, currentKeyB)) {
        return false;
      } else {
        continue;
      }
    }

    if (currentKeyA instanceof Date && currentKeyB instanceof Date) {
      if (currentKeyA.getTime() !== currentKeyB.getTime()) {
        return false;
      } else {
        continue;
      }
    }

    if (currentKeyA instanceof Object && currentKeyB instanceof Object) {
      if (!areObjectsEqual(currentKeyA, currentKeyB)) {
        return false;
      } else {
        continue;
      }
    }

    if (currentKeyA !== currentKeyB) {
      return false;
    }
  }

  return true;
}

function areArraysEqual<T>(arrayA: Array<T>, arrayB: Array<T>) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  for (let i = 0; i < arrayA.length; i++) {
    const arrayElementA = arrayA[i];
    const arrayElementB = arrayB[i];

    if (arrayElementA instanceof Array && arrayElementB instanceof Array) {
      if (!areArraysEqual(arrayElementA, arrayElementB)) {
        return false;
      } else {
        continue;
      }
    }

    if (arrayElementA instanceof Date && arrayElementB instanceof Date) {
      if (arrayElementA.getTime() !== arrayElementB.getTime()) {
        return false;
      } else {
        continue;
      }
    }

    if (arrayElementA instanceof Object && arrayElementB instanceof Object) {
      if (!areObjectsEqual(arrayElementA, arrayElementB)) {
        return false;
      } else {
        continue;
      }
    }

    if (arrayElementA !== arrayElementB) {
      return false;
    }
  }
  return true;
}

export function getTargetPage(rowCount: number, pageSize: number) {
  let targetPage = Math.ceil((rowCount + 1) / pageSize);
  if (targetPage > 0) {
    targetPage = targetPage - 1;
  }
  let lastPage = Math.ceil(rowCount / pageSize);
  if (lastPage > 0) {
    lastPage = lastPage - 1;
  }
  const isFirstRowOnNewPage = (rowCount + 1) % pageSize === 1;
  if (targetPage > lastPage && !isFirstRowOnNewPage) {
    targetPage = lastPage;
  }

  return targetPage;
}

export function areThereSomeUnsavedChanges<
  TRow extends IIndexedRow & { [key in TPath]: Primitive },
  TPath extends keyof TRow,
>(params: {
  formState: IFormState;
  actualValues: InnerTRow<TRow, TPath>[];
  ignoreChangesOnRowIndex?: number;
}): boolean {
  const areThereSomeDirtyFields = !!params.formState.dirtyFields.filter((field) => {
    if (params.ignoreChangesOnRowIndex !== undefined) {
      return field.index !== params.ignoreChangesOnRowIndex;
    }
    return true;
  }).length;
  const areThereSomeNewRows = !!params.actualValues.filter((row) => {
    if (params.ignoreChangesOnRowIndex !== undefined && row.index === params.ignoreChangesOnRowIndex) {
      return false;
    }
    return row.isNew;
  }).length;

  return areThereSomeDirtyFields || areThereSomeNewRows;
}
