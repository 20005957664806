import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, Typography } from '@mui/material';
import * as React from 'react';
import { IntlShape } from 'react-intl';
import {
  IAutocompleteCell,
  IChangeDependendValue,
  INumberCell,
  IStringCell,
} from '../../../../../components/FormTableV2/data';
import { CallSettingOptionIcon } from '../CallSettingOptionIcon';
import { CallSettingOptionLabel } from '../CallSettingOptionLabel';
import { IButtonRenderData, ICallSettingOption } from '../hooks/useGetStateData';
import messages from '../messages';
import { getButtonName, getChipColor, getOptionGroupName, getPlaceholder, isOptionDisabled } from './helpers';

export function createTableDefinition(
  formatMessage: IntlShape['formatMessage'],
  callDestinationOptions?: ICallSettingOption[]
) {
  return [
    {
      decimalPoints: 0,
      editable: false,
      field: 'buttonNumber',
      headerName: formatMessage(messages.labelButton),
      width: 64,
    } as INumberCell<IButtonRenderData, 'buttonNumber'>,
    {
      editable: true,
      field: 'buttonName',
      headerName: formatMessage(messages.labelButtonName),
      maxLength: 255,
      width: 200,
    } as IStringCell<IButtonRenderData, 'buttonName'>,
    {
      editable: false,
      field: 'ringingGroup',
      headerName: formatMessage(messages.labelRingGroup),
      maxLength: 255,
      width: 160,
    } as IStringCell<IButtonRenderData, 'ringingGroup'>,
    {
      editable: true,
      field: 'selectedOptions',
      flex: 1,
      freeSolo: false,
      getIsOptionDisabled: (option, rowData) => isOptionDisabled(option, rowData.selectedOptions),
      getOptionLabel: (option) => (option.optionName || option.optionKey?.toString()) ?? '',
      groupBy: (option: ICallSettingOption) => getOptionGroupName(option.optionKind, formatMessage),
      headerName: formatMessage(messages.labelDevicesToCall),
      inputPlaceholder: (rowData) => getPlaceholder(formatMessage, rowData),
      isOptionEqualToValue: (option: ICallSettingOption, value: ICallSettingOption) =>
        option.optionKey === value.optionKey && option.optionKind === value.optionKind,
      multiple: true,
      onChange: (currentValue: ICallSettingOption[], value: ICallSettingOption[], rowData) => {
        let changeDependendValue: IChangeDependendValue<'buttonName'> | undefined = undefined;
        if (value?.length === 1 && value[0].optionKind === 'Apartments' && rowData?.buttonName === '') {
          changeDependendValue = {
            field: 'buttonName',
            value: getButtonName(rowData.buttonNumber, rowData.buttonName, value),
          };
        }

        if (currentValue?.length === 1 && currentValue[0].optionKind === 'Apartments') {
          return {
            changeDependendValue,
            options: value.filter((option) => option.optionKey !== currentValue[0].optionKey),
          };
        }

        let updatedValue: ICallSettingOption[] = [];
        if (currentValue !== undefined && currentValue.length < value.length) {
          value.forEach((newSelectedOption) => {
            const clonedOption = newSelectedOption;
            if (
              currentValue.findIndex(
                (oldOption) =>
                  oldOption.optionKey === newSelectedOption.optionKey &&
                  oldOption.optionKind === newSelectedOption.optionKind
              ) < 0
            ) {
              clonedOption.hasUpdated = true;
            }
            updatedValue.push(clonedOption);
          });
        } else {
          updatedValue = value;
        }

        return { changeDependendValue, options: updatedValue };
      },
      options: callDestinationOptions ?? [],
      renderOption: (props, option, _state, _ownerState) =>
        CallSettingOptionLabel({
          numberColor: 'primary.dark',
          option,
          props,
        }),
      renderTags: (value, getTagProps) =>
        value.map((option: ICallSettingOption, index) => (
          <Chip
            {...getTagProps({ index })}
            color={getChipColor(option)}
            deleteIcon={<CancelIcon />}
            icon={
              <CallSettingOptionIcon
                deviceAsIcon={option.optionIcon.deviceAsIcon}
                isNew={option.hasUpdated}
                stringAsIcon={option.optionIcon.stringAsIcon}
              />
            }
            key={option.optionKey}
            label={<Typography variant="body2">{option.optionName}</Typography>}
            sx={{
              ...(!option.optionName && {
                '& .MuiChip-label': {
                  px: '6px !important',
                },
              }),
            }}
          />
        )),
    } as IAutocompleteCell<ICallSettingOption, IButtonRenderData, 'selectedOptions'>,
  ];
}
