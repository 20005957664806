import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '../../../../components/HookFormFields/TextField';
import { LANGUAGES } from '../../../../config/consts';
import { getLangSelectOptionLabel } from '../../../../modules/localization/helpers';
import messages from './messages';

function UserLangSelect({ currentUserLang, selectedLang, siteLang }) {
  const { formatMessage } = useIntl();

  return (
    <TextField
      fullWidth
      label={<FormattedMessage {...messages.UserInfoFormLangSelectLabel} />}
      margin="normal"
      name="lang"
      select
      value={selectedLang}
    >
      {Object.values(LANGUAGES).map((lang) => (
        <MenuItem key={lang.locale} value={lang.locale}>
          <Typography>{getLangSelectOptionLabel(lang, siteLang, currentUserLang, formatMessage)}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}

UserLangSelect.propTypes = {
  currentUserLang: PropTypes.string,
  selectedLang: PropTypes.string.isRequired,
  siteLang: PropTypes.string,
};

UserLangSelect.defaultProps = {
  currentUserLang: '',
  siteLang: '',
};

export default UserLangSelect;
