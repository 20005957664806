import { CONTEXT_REMOTE_CONFIGURATION } from '../../config/devices';
import { getOffset } from '../../helpers/dataGrid';
import types from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const addDevice = (values, device, formActions, floors = [], onStep = () => {}) => ({
  deviceType: device,
  floors,
  formActions,
  onStep,
  type: types.ADD_DEVICE_REQUEST,
  values,
});

export const getDeviceDetailRequest = (companyId, siteId, deviceId) => ({
  companyId,
  deviceId,
  siteId,
  type: types.GET_DEVICE_DETAIL_REQUEST,
});

export const editDevice = (values) => ({
  type: types.EDIT_DEVICE_REQUEST,
  values,
});

export const saveDeviceSwitchCodes = (values) => ({
  type: types.SAVE_DEVICE_SWITCH_CODES_REQUEST,
  values,
});

export const deleteDevice = (id, context) => ({
  context,
  id,
  type: types.DELETE_DEVICE_REQUEST,
});

export const getFirmware = (device) => ({ device, type: types.GET_FIRMWARE_VERSION_REQUEST });

export const getNotVerifiedDevicesRequest = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_NOT_VERIFIED_DEVICES_REQUEST,
});

export const getDefaultPasswordDevices = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 1,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_DEFAULT_PASSWORD_DEVICES_REQUEST,
});

export const getCompanyAdminDevicesListRequest = (
  companyId,
  siteId,
  groupId,
  userId,
  page = 1,
  rowsPerPage = 15,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  type: types.GET_COMPANY_ADMIN_DEVICES_REQUEST,
  userId,
});

export const getDevicesListRequest = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'name',
  context = '',
  include = undefined
) => {
  return {
    companyId,
    context,
    filter,
    order,
    page,
    params: {
      fetchConfiguration: false,
      filter: filter.fulltext,
      include,
      limit: rowsPerPage,
      offset: getOffset(page, rowsPerPage),
      order,
    },
    rowsPerPage,
    siteId,
    type: types.GET_DEVICES_REQUEST,
  };
};

export const getUsersProfileDevicesRequest = (
  page = 1,
  rowsPerPage = 15,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  type: types.GET_USER_PROFILE_DEVICES_REQUEST,
});

export const getWaitingDevicesListRequest = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 15,
  filter = { fulltext: '' }
) => ({
  companyId,
  filter,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
  },
  rowsPerPage,
  siteId,
  type: types.GET_WAITING_DEVICES_REQUEST,
});

export const addDeviceStopWait = () => ({ type: types.ADD_DEVICE_STOP_WAIT });
export const addDeviceClearModalData = () => ({ type: types.ADD_DEVICE_CLEAR });

const countDevice = (context) => ({ context, type: types.COUNT_DEVICE_REQUEST });
export const countRemoteConfigurationDevices = () => countDevice(CONTEXT_REMOTE_CONFIGURATION);

export const verifyDeviceShowModal = (device) => ({ device, type: types.VERIFY_SHOW_MODAL });
export const verifyDeviceHideModal = () => ({ type: types.VERIFY_HIDE_MODAL });
export const verifyDeviceStopWait = () => ({ type: types.VERIFY_DEVICE_STOP_WAIT });

export const renewMobileVideoConfiguration = () => ({
  type: types.MOBILE_VIDEO_RENEW_REQUEST,
});

export const updateService = (values) => ({
  type: types.UPDATE_DEVICE_SERVICE_REQUEST,
  values,
});

export const changePhonebook = (values) => ({
  type: types.UPDATE_DEVICE_PHONEBOOK_REQUEST,
  values,
});

export const updateFirmware = (values) => ({
  type: types.UPDATE_FIRMWARE_REQUEST,
  values,
});

export const changeMobileVideoStatus = (values, confirm = true) => ({
  confirm,
  type: types.CHANGE_DEVICE_SERVICE_STATUS_REQUEST,
  values,
});

export const approveDeviceRequest = (device, values, floors) => ({
  device,
  floors,
  type: types.APPROVE_DEVICE_REQUEST,
  values,
});

export const rejectDeviceRequest = (device) => ({
  device,
  type: types.REJECT_DEVICE_REQUEST,
});

export const connectToRCWebsocket = (deviceId) => ({
  deviceId,
  type: types.RC_WEBSOCKET_KNOCK_REQUEST,
});

export const renewRemoteConfiguration = (deviceId) => ({
  deviceId,
  type: types.RC_RENEW_REQUEST,
});

export const activateRemoteConfiguration = (deviceId, active) => ({
  active,
  deviceId,
  type: types.RC_ACTIVATE_REQUEST,
});

export const createServiceConfiguration = (deviceId, service) => ({
  deviceId,
  service,
  type: types.RC_CREATE_REQUEST,
});

export const rcShowModal = (device) => ({ device, type: types.RC_SHOW_MODAL });
export const rcHideModal = () => ({ type: types.RC_HIDE_MODAL });

export const saveDeviceContactListRequest = (values, serviceType = null) => ({
  serviceType,
  type: types.SAVE_DEVICE_CONTACT_LIST_REQUEST,
  values,
});

export const getCallSettingsDevices = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 15,
  filter = { types: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
    types: filter.types,
  },
  rowsPerPage,
  siteId,
  type: types.GET_CALL_SETTINGS_DEVICES_REQUEST,
});

export const getCallSettingsDevicesChips = (companyId, siteId) => ({
  companyId,
  params: {
    limit: 1000,
    offset: 0,
  },
  siteId,
  type: types.GET_CALL_SETTINGS_DEVICES_CHIPS_REQUEST,
});

export const loadUsers = (companyId, siteId) => ({
  companyId,
  siteId,
  type: types.LOAD_USERS_REQUEST,
});

export const updateDtmfCode = (code) => ({
  code,
  type: types.UPDATE_DTMF_CODE_REQUEST,
});

export const verifyDevice = (values) => ({
  type: types.VERIFY_DEVICE_REQUEST,
  values,
});

export const saveCallSettingsMultiple = (values) => ({
  type: types.SAVE_CALL_SETTINGS_MULTIPLE_REQUEST,
  values,
});

export const saveFeatureFaceTracking = (values) => ({
  type: types.SAVE_DEVICE_FEATURE_FACE_TRACKING_REQUEST,
  values,
});

export const deleteDeviceModalShow = (id) => ({ id, type: types.DELETE_DEVICE_MODAL_SHOW });
export const deleteDeviceModalHide = () => ({ type: types.DELETE_DEVICE_MODAL_HIDE });

export const setDeviceEditing = (isEditing) => ({
  isEditing,
  type: types.SET_DEVICE_EDITING,
});
