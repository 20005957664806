import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IModalBase } from '../../../modules/commonTypes';
import { InfoModal } from '../../_DesignSystemWrappers/Modal';
import messages from './messages';

export enum UnsaveChangesModalContext {
  BeforeSkipToNewPage = 'InfoBeforeSkipToNewPage',
  BeforeDeleteAnotherItem = 'BeforeDeleteAnotherItem',
}
export interface IUnsavedChagesModalProps extends IModalBase {
  context: UnsaveChangesModalContext | undefined;
}

export function UnsaveChangesModal(props: IUnsavedChagesModalProps) {
  const { formatMessage } = useIntl();
  return (
    <InfoModal
      title={formatMessage(messages.CallSettingsModalUnsavedChangesTitle)}
      onClose={props.onClose}
      primaryButtonAction={props.onClose}
      buttonText={formatMessage(messages.CallSettingsModalUnsavedChangesSubmit)}
      open={props.open}
      muiDialogProps={{ maxWidth: 'xs' }}
    >
      {props.context === UnsaveChangesModalContext.BeforeSkipToNewPage ? (
        <FormattedMessage {...messages.CallSettingsModalUnsavedChangesText} />
      ) : (
        <FormattedMessage {...messages.CallSettingsModalUnsavedChangesTextClear} />
      )}
    </InfoModal>
  );
}
